.news-container-home {
    margin-left: 120px;
    margin-right: 120px;
}


.articles-container-home {
    margin-left: 120px;
    margin-right: 120px;
}

.health-container-home {
    margin-left: 120px;
    margin-right: 120px;
}