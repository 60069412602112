.kf-body {
  background-color: #ccdae2;
}

.kf-form {
  width: 580px;
  margin: 50px auto;
  padding: 50px;
  box-sizing: border-box; /* Ensure padding is included in the width and height */
  background-color: #63a8d3;
  border-radius: 4px;
  box-shadow: 20px 20px 20px rgba(0.6, 0, 0, 0.1);
}

label {
  font-weight: bold;
}
.kf-input-select {
  display: grid;
  height: 15px;
  width: 500px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  font-family: "Comic Sans MS";
}

.kf-input-select {
  height: 40px;
}
.T-kf-button{
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;

}

.kf-button {
  padding: 12px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
/*kkjnkjj*/
.kf-button:hover {
  background-color: #0056b3;
}

.kf-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


  
.kf-input{
  
  display: flex;

}

.actions {
  display: flex;
}

.actions button {
  margin-right: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th,
table td {
  padding: 8px;
  border: 1px solid #ddd;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #f5f5f5;
}
.delete{
  margin-right: 5px; 
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  box-sizing: border-box; /* Ensure padding is included in the height */
}

/* Popup window CSS */
.image-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.image-popup-content {
  position: relative;
  padding: 25px;
  background: #fff;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.image-popup-content img {
  width: 100%;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
}

.close {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 25px;
  cursor: pointer;
}

/* Additional styles to prevent body scrolling when the popup is open */
body.modal-open {
  overflow: hidden;
}
