.business-user-activate {
    margin-left: -100px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.business-user-activate table {
    border-collapse: collapse;
    width: 30%;
}

.business-user-activate th,
.business-user-activate td {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
    border: 1px solid #ddd;
    padding: 8px;
}

.business-user-activate th {
    text-align: left;
    background-color: #f2f2f2;
}

.business-user-activate tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}