
.input {
  font-size: larger;
  padding: 5px;
  margin: 2px;

}

.error-message-facebook{
  color: red;
  font-size: 19px;
  font-style: italic;
  padding: 20px;
}

.post-row{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
}

.icon-reload{
  height: 30px;
  width: 30px;
}

.previous-post-header {
  font-size: 18px;
  font-weight: 400;
  color: #2e2e2e;
  font-family: "IBM Plex Sans", sans-serif;
}

.metrics-display{
  display: flex;
  flex-direction: row;
}

.count-display{
  padding: 4px 8px 0px 20px;
}

.facebook-table {
  .checkbox-header {
    width: 10px;
    text-align: center;
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }
  }

  .checkbox-column {
    width: 10px;
    text-align: center;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border: 3px solid black;
    box-shadow: .5rem .5rem 0 black;
    border-radius: 20px;
  }

  th {
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    background-color: #005af0;
    font-family: "IBM Plex Sans";
  }

  td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    color: #000000;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }

  tr {
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.button-blue {
  text-decoration: none;
  background-color: #005af0;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
  border-radius: 5px;

  &:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
  }

  &:disabled {
    background-color: #d1d1d1;
    color: #757779;
    outline: none;
  }
}