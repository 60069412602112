.startup-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
}
.linked{
  width:40px;
}

  .startup-table th, .startup-table td {
    border: 1px solid #ddd;
    padding: 12px; /* Increase padding for better spacing */
    text-align: left;
  }
  
  .startup-table th {
    background-color: #85c1ee; /* Header background color */
  }
  
  .startup-table td {
    background-color: #fff; /* Row background color */
  }
  
  .startup-table td:hover {
    background-color: #f5f5f5; /* Hover effect on rows */
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination span {
    margin: 0 8px;
    cursor: pointer;
    padding: 8px 12px; /* Increase padding for better appearance */
    border: 1px solid #ddd;
    border-radius: 4px; /* Add rounded corners */
  }
  
  .pagination span.active {
    background-color: #30ade8;
    color: white;
    border-color: #30ade8;
  }
  
  /* Optional: Add box shadow to the table for a subtle effect */
  .startup-table {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 2rem; /* Adjust the font size as needed */
    color: #333; /* Set the text color */
    margin-bottom: 20px; /* Add spacing below the heading */
    margin-left: 40px;
    margin-right: 40px;
    
  }
  
  .P1 {
    font-size: 1rem; /* Adjust the font size as needed */
    color: #403d3d; /* Set the text color */
    text-align: justify; /* Justify the text */
    width: 1300px; /* Limit the width of the paragraph */
    margin-bottom: 20px; /* Add spacing below the paragraph */
    margin-left: 40px;
    margin-right: 40px;
  }

  /* Simple CSS spinner */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-circle {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
