/* CSS for the link box */
/* .myApp-body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100vh; 
  display: flex; 
} */

.myApp-home {
  text-align: center;
  /* flex: 1;  */
  /* display: flex;  */
  /* justify-content: center;  */
  align-items: center; 
  margin-left: 280px;
  max-height:1000px;
  overflow-y: auto;
}

/* Link box */
.myApp-link-box {
  background-color: #e0eaf0; 
  width: 15%; 
  padding: 20px 20px 20px 40px; 
  text-align: left; 
  border-radius: 10px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  /* height: 100vh; */
  max-height:2000px;
 
  
  
}

/* Styles for links */
.myApp-link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  display: block; /* Display each link on a new line */
  margin-bottom: 10px; /* Add spacing between links */
}

.myApp-link:hover {
  color: #555;
}


@media screen and (max-width: 768px) {

  /* Example: Make the link box take up the full width */
  .myApp-link-box {
    width: 50%; /* Take up the full width */
    padding: 20px; /* Adjust padding as needed */
  }
  
  /* Example: Reduce the font size for links on smaller screens */
  .myApp-link {
    font-size: 14px; /* Adjust font size as needed */
    margin-bottom: 8px; /* Adjust spacing between links */
  }

  .myApp-home {
    margin-left: 0; /* Remove the left margin on smaller screens */

  }
}