.activity-portal {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .activity-portal h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .activity-portal table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .activity-portal th,
  .activity-portal td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
  
  .activity-portal th {
    font-weight: bold;
    background-color: rgb(16, 58, 131);
  }
  
  .activity-portal td {
    vertical-align: top;
  }

 