.fb-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Heading */
  .heading {
    text-align: center;
    color: #333;
  }
  
  /* Individual post container */
  .post {
    background-color: #d9edf7; 
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Post title */
  .post-title {
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Post caption */
  .post-caption {
    color: inherit; 
    line-height: 1.5;
  }
  
  /* Alternate color for every other post */
  .posts-container .post:nth-child(even) {
    background-color: #dff0d8; /* Light green */
  }
  
  /* Hover effect on posts */
  .post:hover {
    background-color: #cfe2f3;;
    transform: translateY(-2px);
    transition: transform 0.2s ease-in-out;
  }