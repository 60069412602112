/* ReportPage.css */

.report-page {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  form {
    display: grid;
    gap: 10px;
  }
  
  .report-page label {
    display: grid;
    grid-template-columns: 150px 1fr;
    margin-bottom: 10px;
  }
  
  input[type='text'],
  select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  input[type='text']:focus,
  select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /*input[type='text'][name='percentage'] {
    display: ${status === 'complete' ? 'block' : 'none'};
     
  }*/
  