/* styles.css */

.custom-leaders-form {
    width: 550px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height:auto;
  }
  
  .custom-form-body {
    display: flex;
    flex-direction: column;
  }
  
  .custom-form-label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .custom-form-input,
  .custom-form-textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .custom-form-textarea {
    resize: vertical;
  }
  
  .custom-form-button {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .custom-form-button:hover {
    background-color: #45a049;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  table th,
  table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  table th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
  }
  
  table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  table tr:hover {
    background-color: #f5f5f5;
  }
  
  .row-container {
    display: flex;
    flex-direction: row;
  }  

  .L-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 3px;
  }
  
  .L-button:hover {
    background-color: #0056b3;
  }
  
  .L-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }