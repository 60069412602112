.healthbook-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .link-healthbook {
    margin: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .link-healthbook:hover {
    background-color: #e0e0e0;
  }