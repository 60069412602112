.documents-container {
    /* position: relative; */
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px;
  
    overflow-y: auto;
}

.loader-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.loader {
    width: 100px;
    height: 100px;
}

ul.loading {
    opacity: 0.5;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.pagination-button {
    padding: 8px 12px;
    margin: 2px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    cursor: pointer;
    color: #000; /* Default text color */
}

.pagination-button.active {
    background-color: #007bff;
    color: white; /* Active button text color */
    border-color: #007bff;
}

.pagination-button:hover:not(.active) {
    background-color: #ddd;
}

