
.singlecol{
    max-width: 50px;
    margin: 0 auto;
}
.social-media-icons-white a  {
    color:white;
    font-size: 1.3rem;
    
}
 .heading1{
    color: white;
    margin-left: 8px;
    margin-right: 20px;
    
 }
 .heading-place{
    color: red;
    margin-left: 8px;
    margin-right: 20px;
    
 }


 .scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    color: white;
}

.card {
    border: 1px solid black;
    box-shadow: 0 0 5px black;
    width: 250px;
    margin: 20px;
}
.card-imgage img {
    width:249px;
}

.color-nav{
    background-color: #1A106B;
    color: aliceblue;
    flex-wrap: wrap;
    
}

.color-nav-techie{
    background-color: #c60021;
    color: aliceblue;
    flex-wrap: wrap;
}


.head22{
    background-color: rgba(217, 210, 210, 0.868);
    color: white;
    
}
.head-2{
    margin-left: 8px;
    color: white;
}

.red-link {
    color: red;
    text-decoration: none;
  }

  .red-link:hover {
    color: red; /* or any color you prefer on hover */
    text-decoration: none;
  }
  @media screen and (max-width: 768px) {
    .color-nav{
    width: 560px;
    }
  }

  .logo-techieindex{
    width:36%;
}

.header{
    background-color: #1A106B;
  }

  .header-techie{
    background-color: #c60021;
  }

  .greeting-text {
    color: white; /* Adjust based on navbar background */
    font-weight: bold;
    font-size: 1rem;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.2); /* Subtle highlight */
    border-radius: 5px;
}
