.fb-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .posts-container {
    display: grid;
    gap: 20px;
  }
  
  .post {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
  }
  
  .post-title {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  
  .post-date {
    font-style: italic;
    margin-bottom: 5px;
  }
  
  .post-caption {
    margin-bottom: 5px;
  }
  
  .post-likes,
  .post-comments,
  .post-shares,
  .post-engagement {
    font-weight: bold;
  }