.facebookschedule-content-container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px;
    /* Remove fixed height and let the content expand naturally */
    /* height: 2400px; */
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
  }

  .facebookschedule-content-container label {
    width: 50%; /* Set the label width to 50% of the container */
  }


  .facebookschedule-content-container input[type="date"] {
    width: calc(50% - 6px);
    margin-right: 12px;
  }
  
  .facebookschedule-content-container input[type="time"] {
    width: calc(25% - 1px); /* Adjust the width to your preference */
  }