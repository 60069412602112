.footer {
  background-color: rgb(16, 58, 131);
  color: #fff;
  padding: 20px 0; /* Decreased padding */
  margin-top: 40px; /* Decreased margin-top */
}

.footer-techie {
  background-color: #c60021;
  color: #dddddd;
  padding: 20px 0; /* Decreased padding */
  margin-top: 20px; /* Decreased margin-top */
}

footer .container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px; /* Decreased grid-gap */
}

footer .logo p {
  margin: 10px 0; /* Decreased margin */
}

footer h3 {
  margin-bottom: 10px; /* Decreased margin-bottom */
  font-weight: 500;
}

footer .item {
  display: flex;
  margin-bottom: 20px; /* Decreased margin-bottom */
}

footer .item img {
  width: 60px; /* Decreased image size */
  height: 60px; /* Decreased image size */
  object-fit: cover;
}

footer img {
  width: 250px;
}

footer .item p {
  padding: 5px 10px; /* Decreased padding */
}

footer ul li {
  margin-bottom: 5px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

footer ul li:nth-last-child(1) {
  border-bottom: none;
}

footer ul li span::before {
  content: ">";
  top: 0;
  left: 0;
  margin-right: 5px; /* Decreased margin-right */
}

.legal {
  text-align: center; /* Center the text horizontally */
  height: 25px; /* Decreased height */
  background-color: rgb(16, 58, 131);
  color: #dddddd;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
}

.legal-techie {
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  text-align: center; /* Center the text horizontally */
  height: 25px; /* Decreased height */
  background-color: #c60021;
  color: #dddddd;
}

.legal i {
  color: red;
}

.legal p {
  margin: 0;
}

.justify-text {
  text-align: justify;
}

.footer .box {
  
  padding: 3px;
  margin-bottom: 10px;
  
  height: 50px; 
   width: auto;
  white-space: nowrap;
}

.footer .box-myblocks {
  
  padding: 3px;
  margin-bottom: 10px;
  
  height: 40px; 
   width: auto;
  white-space: nowrap;
}



@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px; 
  }

footer .box ul {
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

footer .box ul li {
  margin-bottom: 5px;

  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.footer {
  width: 560px;
}
 .legal {
  width: 560px;
}
}

.logo-techie{
  height:100%;
}

.tagline {
  margin: 20px 20px;
  text-align: justify;
  width: 40rem;
}