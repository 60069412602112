.register-form-container h2 {
    margin-bottom: 20px;
}

.register-form-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}



.register-form-container .forgot-password {
    text-align: right;
}

.register-form-container .register-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
   
 
}

.register-form-container .register-button:hover {
    background-color: #0056b3;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f2f2f2;
    margin: 0;
    padding: 0;
}


.register-form-container {
    max-width: 600px; 
    margin: 1px auto 0; 
    background-color: #fff; /* Set a background color */
    padding: 20px; /* Add padding to the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for a card-like effect */
    border-radius: 10px; /* Add rounded corners */
    /* padding-top: 20px; */
    min-height: 400px;
    /* height: 600px; */


    
}

 .register-button-container {
    display: flex;
    justify-content: center; /* Center items horizontally */
   
}

.register-button-container .register-button  {
    display: inline-block;
    margin: 10px 10px; /* Add some horizontal spacing between buttons if needed */
    
}

.error-message {
    color: red;
    font-size: 14px; /* You can adjust the font size as needed */
  }
 


  /*
  .radio-label1 {
    padding-left: 100px; 
  }

  .register-form-container .input-radio {
    margin: 0 5px 0 0; 
   

    cursor: pointer;
 
  } */

  .radio-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
    margin-left: 1px;
  }
  
  .radio-label1 {
    margin-left: 200px;
  
    width: 180px; 
  }
  
  .input-radio {
    /* Styles for the radio button */
    width: 18px; /* Set width */
    height: 18px; /* Set height */
    cursor: pointer; /* Add cursor on hover */
    margin-right: 5px; /* Adjust the margin to control the distance between the radio button and label */
  }
  
  /* Add styles for the second radio button if needed */
  .radio-label {
    margin-left: 10px;
   
    width: 100px; 
    /* Adjust the margin to control the distance between the first and second labels */
  }

 

