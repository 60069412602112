/* .hero_articles{
    margin: 5px 0;
    color: black;

}
 .hero_articles a{
    color: #fff;
 }

 .hero_articles .box{
    position: relative;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 1px rgb(198, 197, 197);
    height: auto;
    
 }
 .hero_articles img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
    filter: brightness(70%)
 }
 .hero_articles .box:nth-child(1) img {
   height: 100%;
}
 .hero_articles .container{
    display:grid;
    grid-template-columns: repeat(8fr, 1fr);
    grid-template-rows: repeat(8,6.6vh);
    grid-gap:15px;
    
 }

.hero_articles .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 7;
}
.hero_articles .box:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 4;
}
.hero_articles .box:nth-child(3) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 4;
}
.hero_articles .box:nth-child(4) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 7;
}
.hero_articles .box:nth-child(5) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 7;
}
.hero_articles .box:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 10;
}
.hero_articles .box:nth-child(7) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 10;
}
.hero_articles .box:nth-child(8) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 7;
    grid-row-end: 10;
}
.hero_articles .box:nth-child(9) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 10;
}
.hero_articles .box:nth-child(10) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 10;
    grid-row-end: 13;
}

.hero_articles .text h1{
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
}
.hero_articles .author span{
    font-size: 12px;
    margin-right: 20px;
    font-weight: 400;
}
.hero_articles .text {
    position: absolute;
    bottom: 0px;
    height: 50%;
    text-decoration: none;
}
.hero_articles .box:nth-child(1) .text {
    height: 30%;
    bottom: 10px !important;
}
.hero_articles .box:nth-child(1) h1 {
    font-size: 28px;
    color: #fff;
}
.hero_articles .box:nth-child(1) span{
    color: #fff;
}

.hero_articles .box:nth-child(1) .category {
    background-color: crimson ;
}
.hero_articles .box:nth-child(2) .category {
    background-color: #0b9931 ;
}

.hero_articles .box:nth-child(4) .category {
    background-color: #c78823 ;
}

@media screen and (max-width: 768px) {
    
        .hero_articles .container {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(18,6vh);
            grid-gap:10px;
         }
        
        .hero_articles .box:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 9;
            grid-row-start: 1;
            grid-row-end: 5 ;
        }
        .hero_articles .box:nth-child(2) {
            grid-column-start: 1;
            grid-column-end: 9;
            grid-row-start: 5;
            grid-row-end: 10;
        }
        .hero_articles .box:nth-child(3) {
            grid-column-start: 1;
            grid-column-end: 9;
            grid-row-start: 10;
            grid-row-end: 15;
        }
        .hero_articles .box:nth-child(4) {
            grid-column-start: 1;
            grid-column-end: 9;
            grid-row-start: 15;
            grid-row-end: 20;
        }
        .hero_articles .box:nth-child(5) {
            display: none;
        }
        .hero_articles .box:nth-child(6) {
            display: none;
        }
        .hero_articles .box:nth-child(7) {
            display: none;
        }
        .hero_articles .box:nth-child(8) {
            display: none;
        }
        .hero_articles .box:nth-child(9) {
            display: none;
        }

    
} */

.articles-container .archives-link {
    
    margin-top: 17px; 
}

/* .articles-container {
    margin-left: 50px !important; 
   margin-right: 50px !important;
   
} */

.articles-container .hero_articles{
   
    margin: 10px 0;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
   /* margin-bottom: 90px;*/
 

}
.articles-container .hero_articles a{
    color: black;
 }

 .articles-container .hero_articles .box{
    position: relative;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 1px rgb(198, 197, 197);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: auto;
 }

 .articles-container .hero_articles img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-radius: 2px;
 }

 .articles-container .hero_articles .content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
    
  }

  .articles-container .hero_articles .box:nth-child(1) img {
    height: 70%;
 }

 .articles-container  .hero_articles .text h1{
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
   
}
.articles-container .hero_articles .author span{
    font-size: 12px;
    margin-right: 20px;
    font-weight: 400;

    
}
.articles-container .hero_articles .text {
    position: relative;
    padding: 10px;
    text-align: center;
    color: black;
    background: none;
    opacity: 0.9;
    z-index: 1;
    margin-top: 140px; /* Reset margin-top */
    margin-bottom: -20px; /* Add margin to create space between text and image */
 
}

.articles-container .hero_articles .box:nth-child(1) .text {
    height: 30%;
    bottom: 10px;
    margin-top: 40%;
}
.articles-container .hero_articles .box:nth-child(1) h1 {
    font-size: 28px;
    color: black;
   
}
.articles-container .hero_articles .box:nth-child(1) span{
    color: #fff;
}

.articles-container .hero_articles .box:nth-child(1) .category {
    background-color: crimson ;
}
.articles-container .hero_articles .box:nth-child(2) .category {
    background-color: #0b9931 ;
}

.articles-container .hero_articles .box:nth-child(4) .category {
    background-color: #c78823 ;
}

.articles-container .hero_articles .container{
    display: grid;
    grid-template-columns: repeat(8fr, 1fr);
    grid-template-rows: repeat(8,6.6vh);
    grid-gap:15px;
    min-height: 400px;
    white-space: nowrap;
    
 }

 .articles-container .hero_articles .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 7;
}
.articles-container .hero_articles .box:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 4;
}
.articles-container .hero_articles .box:nth-child(3) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 4;
}
.articles-container .hero_articles .box:nth-child(4) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 7;
}
.articles-container .hero_articles .box:nth-child(5) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 7;
}
.articles-container .hero_articles .box:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 10;
}
.articles-container .hero_articles .box:nth-child(7) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 10;
}
.articles-container .hero_articles .box:nth-child(8) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 7;
    grid-row-end: 10;
}
.articles-container .hero_articles .box:nth-child(9) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 10;
}
.articles-container .hero_articles .box:nth-child(10) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 10;
    grid-row-end: 13;
}
.articles-container .hero_articles .box:nth-child(11) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 10;
    grid-row-end: 13;
}
.articles-container .hero_articles .box:nth-child(12) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 10;
    grid-row-end: 13;
}
.articles-container .hero_articles .box:nth-child(13) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 10;
    grid-row-end: 13;
}
.articles-container .hero_articles .box:nth-child(14) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 13;
    grid-row-end: 16;
}
.articles-container .hero_articles .box:nth-child(15) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 13;
    grid-row-end: 16;
}
.articles-container .hero_articles .box:nth-child(16) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 13;
    grid-row-end: 16;
}
.articles-container .hero_articles .box:nth-child(17) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 13;
    grid-row-end: 16;
}
@media screen and (max-width: 768px) {
    .articles-container .hero_articles .box .text h1 {
        font-size: 16px; /* Reduce font size for smaller screens */
      }
      .articles-container .hero_articles .box:nth-child(1) .text {
        
        margin: 140px 0px -30px;
        padding: 85px;
    }
    .articles-container .hero_articles .container {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-auto-rows: repeat(18, 6vh);
        grid-gap: 10px;
    }

   
    
    .articles-container .hero_articles .box:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 5 ;
    }
    .articles-container .hero_articles .box:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 5;
        grid-row-end: 10;
    }
    .articles-container .hero_articles .box:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 10;
        grid-row-end: 15;
    }
    .articles-container .hero_articles .box:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .articles-container .hero_articles .box:nth-child(5) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(6) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(7) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(8) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(9) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(10) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(11) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(12) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(13) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(14) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(15) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(16) {
        display: none;
    }
    .articles-container .hero_articles .box:nth-child(17) {
        display: none;
    }
}
