.gap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height:30vh; */
  background-color: #f5f5f5;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.gap-container .label-gap {
  margin-right: 10px;
}

select {
  padding: 5px;
  font-size: 16px;
}

.button-group {
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

