.uploadmultipieimages-content-container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px;
    /* Remove fixed height and let the content expand naturally */
    /* height: 2400px; */
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
  }

  .centered-link-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }