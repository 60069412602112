/* Home.css */

/* Apply background image styles to the container */
.business-home-container {
  margin: 0;
  padding: 0;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("./images/background-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Style the navigation links */
.business-nav {
  display: flex;
  flex-direction: column; /* Change to column */
  justify-content: flex-start;
  align-items: flex-start; /* Align to the start of the column */
  height: 100vh; /* Adjust the height based on your design */
  background-color: transparent;
  /* position: absolute; */
  top: 0;
  left: 0;
  z-index: 1;
  padding: 30px;
}

.business-nav-a {
  text-decoration: none;
  color: #200cd6;
  margin-bottom: 20px; /* Add margin between links */
  font-size: 25px;
}

/* Content styles */
/* .content {
  margin-left: 210px;
  padding: 30px;
  z-index: 2;
} */
