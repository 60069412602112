.aiCindexContainer {
    font-family: Arial, sans-serif;
    margin: 20px;
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px;
    overflow-y: auto;
}

.title {
    font-size: 2em;
    color: #333;
}

.error {
    color: #f00;
}

.aiTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.aiTable th, .aiTable td {
    padding: 10px;
    /* text-align: left; */
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}

.aiTable th {
    background-color: #f4f4f4;
}

.aiTable tr:nth-child(even) {
    background-color: #f9f9f9;
}



.aiTable .aicheckbox {
    margin-top: 20px;
    /* margin-right: 10px; */
    /* vertical-align: middle; */
}


.selectedList {
    margin-top: 20px;
}

.selectedListTitle {
    font-size: 1.5em;
    color: #333;
}

.selectedListItems {
    list-style-type: none;
    padding: 0;
}

.selectedListItem {
    background-color: #e7f3ff;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
}

.submitButton {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
}