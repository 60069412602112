.why_section .box {
    margin: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
  
  .why_section .box .img-box {
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    border-radius: 100%;
    border: 5px solid #073c81;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .why_section .box .img-box img {
    width: 55px;
    height: auto;
    fill: #00204a;
  }
  
  .why_section .box h5 {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .why_section .box p {
    margin-bottom: 0;
  }
 
  .why_section .h2-wwd {
    margin-top: 20px;
  }
