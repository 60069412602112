.aboutus-popup label {
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    color: red;
    opacity: .4;
    transition: opacity .4s ease-in-out;
    display: block;
    width: calc(100% - 48px);
    text-align: right;
    z-index: 100;
    user-select: none;
  }

  .tabs .input[type="radio"] {
    display: none;
    width: 0;
  }

  .aboutus-popup label:hover,
  input[type="radio"]:checked+label {
    opacity: 1;
  }

  .aboutus-popup {
    padding-top: 40px;
    height: 80%;
    min-height: 480px;
    display: flex;
  }

  .tabs {
    width: 100%;
    max-width: 240px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
  }

  .about-content {
    .header-grey {
      font-size: 30px;
      padding: 10px;
      cursor: pointer;
      color: grey;
    }
    max-width: 60%;
    padding: 0 20px;
  }