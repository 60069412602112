.news-container_mobile .hero_news_mobile .box .text h1 {
    font-size: 16px; /* Reduce font size for smaller screens */
  }

  .news-container_mobile .hero_news_mobile .box .text .date-wrapper {
    height: 15px; /* Reduce height of date span for smaller screens */
  }


.news-container_mobile  .hero_news_mobile .container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: repeat(18, 6vh);
    grid-gap: 10px;
    white-space: nowrap;
}

.news-container_mobile .hero_news_mobile .box:nth-child(1) .text {
    
    margin: 140px 0px -30px;
    padding: 85px;
}

/* .news-container_mobile .hero_news_mobile .box:nth-child(1) .text {    
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 5 ;
} */

.news-container_mobile .hero_news_mobile .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 5 ;
}
.news-container_mobile .hero_news_mobile .box:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 10;
}
.news-container_mobile .hero_news_mobile .box:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 10;
    grid-row-end: 15;
}
.news-container_mobile .hero_news_mobile .box:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(8) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(9) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(10) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(11) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(12) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(13) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(14) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(15) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile .hero_news_mobile .box:nth-child(16) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile  .hero_news_mobile .box:nth-child(17) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}

.news-container_mobile  .hero_news_mobile .box:nth-child(18) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile  .hero_news_mobile .box:nth-child(19) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile  .hero_news_mobile .box:nth-child(20) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile  .hero_news_mobile .box:nth-child(21) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}
.news-container_mobile  .hero_news_mobile .box:nth-child(22) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 15;
    grid-row-end: 20;
}




@media screen and (max-width: 768px) {

    .news-container_mobile .hero_news_mobile .box .text h1 {
        font-size: 16px; /* Reduce font size for smaller screens */
      }
    
      .news-container_mobile .hero_news_mobile .box .text .date-wrapper {
        height: 15px; /* Reduce height of date span for smaller screens */
      }
    
    
    .news-container_mobile  .hero_news_mobile .container {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-auto-rows: repeat(18, 6vh);
        grid-gap: 10px;
        white-space: nowrap;
    }
    
    /* .news-container_mobile .hero_news_mobile .box:nth-child(1) .text {
        
        margin: 140px 0px -30px;
        padding: 85px;
    } */
    
    .news-container_mobile .hero_news_mobile .box:nth-child(1) .text {    
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 5 ;
    }
    
    .news-container_mobile .hero_news_mobile .box:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 5 ;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 5;
        grid-row-end: 10;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 10;
        grid-row-end: 15;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(5) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(6) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(7) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(8) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(9) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(10) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(11) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(12) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(13) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(14) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(15) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile .hero_news_mobile .box:nth-child(16) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile  .hero_news_mobile .box:nth-child(17) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    
    .news-container_mobile  .hero_news_mobile .box:nth-child(18) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile  .hero_news_mobile .box:nth-child(19) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile  .hero_news_mobile .box:nth-child(20) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile  .hero_news_mobile .box:nth-child(21) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container_mobile  .hero_news_mobile .box:nth-child(22) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    
    
    

}