/* Style.css */
.hospital-form {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; 
  position: relative; 
  margin-left: 200px; */


  max-width: 1320px;
  margin: 0 auto;
  padding: 20px;
  /* Remove fixed height and let the content expand naturally */
  /* height: 2400px; */
  overflow-y: auto;
}

.hospitals-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display three hospitals in a row */
  gap: 20px; /* Adjust as needed */
}

.hospital-card {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
}

/* .hospital-card a {
  text-decoration: none;
  color: #333;
} */

.hospital-card img {
  max-width: 450px;
  height: 270px;
}

.title {
  text-align: center;
}

.top-left {
  position: absolute;
  top: 20px;
  left: 1350px; /* Adjust as needed */
}
