.ad{
    margin:  0;
    color: black;
    padding-top: 30px;
    padding-left: 10px;
}
.headad{
    padding-top: 30px;
    padding-left: 100px;
}
.ad a {
    color: black;
}
.ad  .box{
     position:relative;
     padding: 20px;
     box-shadow: 2px 2px 2px 2px rgb(198, 198, 198);
     border-radius: 5px;
     
 }
 .ad a{
    text-decoration: none;
 }

 .ad img{
    width: 100%;
    height: 70%;
    object-fit: cover;
    position: absolute;
    border-radius: 5px;
    top: 0;
    left: 0;
    z-index: -1;
}
.ad .container{
    display:grid;
    grid-template-columns: repeat(8,1fr);
    grid-template-rows: repeat(8,6.6vh) ;
    grid-gap :10px;
}

.ad .box:nth-child(1){
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 9;
}


.ad .text h1 {
font-weight: 500;
margin: 10px 0;
font-size: 20px;


}
.ad .text h2 {
    font-weight: 300; 
    font-size: 15px;
    
    }

.ad .author span{
font-size: 14px;
margin-right: 20px;
}

.ad .text{
    position: absolute;
    bottom: 20px;
}



        @media screen and (max-width: 768px) {
            .ad{
                display: none;
            }
            .headad{
                display: none;
            }
        }