

.page {
    
    display:grid;
    grid-template: 2fr 1fr 2fr 5fr / 2fr 1fr 2fr 5fr   ; 
    column-gap: 10px;  
}
.page h5{
    color: brown;
}
.input {
    
    grid-row: 1/-1;
    grid-column: 1/3   ;
    border: 2px solid gray;
    
}

 .city {
    
    grid-row: 1/-1;
    grid-column: 2/-3;
    border: 2px solid gray;
} 



.block {
    border: 2px solid gray;
}


.mainpage .button{
    width: 200px;
    text-align: center;
    font-weight: 500;
    
}


  
/* Increase horizontal spacing in table cells */
table td,
table th {
  padding-left: 30px; /* Adjust the value as per your requirement */
  padding-right: 30px;
/*   width: 25%;
 */  /* Adjust the value as per your requirement */
}
  
  /* Increase spacing between table rows */
  table tbody tr {
    margin-bottom: 10px; /* Adjust the value as per your requirement */
  }

  table,
th,
td {
  border: 1px solid black;
  padding: 8px;
}

th,
td {
  text-align: left;
}