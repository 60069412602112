.heading_press{
    margin: 30px 110px ;
    background-image: url("../../../../public/images/title_pattern.png");
    height: 35px;
    background-position: center; 
}
.heading_press h6{
    font-weight: 500;
    background-color: #fb4c35;
    color: #fff;
    height: 35px;
    width: 130px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 17px;
}

