.interviewspotlight-container {
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 50px;
  }
  
  .columns-container {
    display: flex;
    justify-content: space-between; 
  }
  
  .column {
    width: 48%; 
    box-sizing: border-box; 
    padding: 0 10px; 
  }
  
  .interviewspotlight-container .footer {
    text-align: center;
    background-color: #CCCCCC;
    padding: 20px;
    color: #808080;
  } 
  
  .interview-card-container {
    display: flex;
    align-items: flex-start; 
  margin-bottom: 20px;
}

.interview-card-container .interview-card-image {
  height: 100px;
  width: 100px;
}

.interview-card-name {
  color: #FF3300;
  margin-left: 5px;
}

.interview-card-company {
  color: #0066cc;;
  margin-left: 5px;
  font-size: 13px;
}



.interview-card-position {
  /* color: #0066cc; */
  margin-left: 5px;
  font-size: 12px;
}

.interview-card-details {
    display: flex;
    flex-direction: column;
  }


  .navlink-Interview{
    text-decoration: none; 
    color:black;

  }

  .navlink-Interview:hover {
    text-decoration: none; 
    color:black;

  }

  .navlink-archives{
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  
  
  
  
  
  
  
  
  
 