.hospital-detail-main-container {
  /* 
  justify-content: left; 
  align-items: left; 
  margin-left: 10%; 
  min-width: 200px; */
  display: flex;
  max-width: 1380px;
  margin: 0 auto;
  padding: 20px;
 
  /* height: 2400px; */
  /* overflow-y: auto; */
  
}





/* HospitalDetail.css */
.hospital-detail {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center horizontally */
  text-align: left; /* Align text to the left */
  width: 400px; /* Set a fixed width for the form */
}



.hospital-detail-review {
  border: 1px solid #ccc; /* Add a frame to the image */
  padding: 10px; /* Add padding to the image frame */
  border-radius: 10px; /* Increase border radius for a rounded frame */
  text-align: center; /* Align the image horizontally */
  margin-bottom: 20px; /* Add space between image and details */
  width: 650px; /* Set a fixed width for the image container */
  height: 850px;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); */
  margin-left: 20px;
}

.hospital-image {
  border: 1px solid #ccc; /* Add a frame to the image */
  padding: 10px; /* Add padding to the image frame */
  border-radius: 10px; /* Increase border radius for a rounded frame */
  text-align: center; /* Align the image horizontally */
  margin-bottom: 20px; /* Add space between image and details */
  width: 350px; /* Set a fixed width for the image container */
  height: 350px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); /* Add a box shadow for a lifted effect */
}

.hospital-image img {
  max-width: 100%; /* Ensure the image doesn't overflow its container */
  height: auto; /* Maintain the image's aspect ratio */
}

.hospital-details {
  text-align: left; /* Align text to the left */
  /* margin-left: 10px;  */
}

.hospital-details h2 {
  margin: 0; /* Remove margin for the heading */
}



/* HospitalDetail.css */
.services-form {
  margin-top: 20px;
}

.services-form h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.services-form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.services-form option {
  font-size: 16px;
}

.services-select + div {
  margin-bottom: 20px;
}

/* Add this CSS to your HospitalDetail.css or a separate CSS file */

/* Style for the user review textarea */
textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  margin-top: 10px;
}

/* Style for the "Submit" button */
.services-form button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

/* Style for the "Submit" button on hover */
.services-form button:hover {
  background-color: #0056b3;
}

/* Style for the login modal */
/* You can adjust the modal styles as needed */
.login-modal {
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Style for the login modal close button */
.close-button {
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #555;
}



.review-container {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  background-color: #f9f9f9;
  text-align: left;
}

.review-container p {
  margin: 5px 0;
}

.review-container p:first-child {
  font-weight: bold;
}


/* Star Rating Container */
.star-rating {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/* Individual Star */
.star {
  font-size: 24px;
  color: #ccc;  /* Set the unselected star color to white */
  cursor: pointer;
  transition: color 0.2s;
}

/* Active (Selected) Star */
.star.active {
  color: #ffd700; /* Set the selected star color to yellow */
}

/* Hover Effect */
.star:hover {
  /* color: #f1c40f;  */
  color: #ffd700; 
}

.static-star p {
  pointer-events: none;
}

.static-star {
  font-size: 24px;
  color: #ccc;  /* Set the unselected star color to white */
  cursor: pointer;
  transition: color 0.2s;
}

/* Active (Selected) Star */
.static-star.active {
  color: #ffd700; /* Set the selected star color to yellow */
}