.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0 0 0;
    width: 1000px;
    border: 2px solid gray;
    padding: 10px 10px 10px 10px;
    margin-left: 20%;
    margin-top: 5%;
    background-color: #7ea1eb;
    box-shadow: 2px 2px 2px 2px grey;

}

.login input[type="text"],
.login input[type="password"] {
    border: 2px solid gray;
    width: 250px;
    border-radius: 5px;
    font-size: 17px;
}

.login button {
    border: 2px solid gray;
    align-items: center;

    width: 100px;
    border-radius: 5px;
    font-size: 18px;

}

.login .Link {
    color: black;
}

.login-body {
    font-family: Arial, sans-serif;
    /* Replace 'your-image-url.jpg' with the actual URL of your image */
    background-size: cover;
    /* Adjust as needed to control the image size */
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* Keeps the background fixed while scrolling */
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.tx-login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    height: 500px;
}

.login-right-side {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
}

.login-form-container h2 {
    margin-bottom: 20px;
}

.login-form-container input,
.continue-options .continue-button,
.login-form-container .login-button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-form-container .forgot-password {
    text-align: right;
}

.login-form-container .login-button {
    background-color: rgb(16, 58, 131);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.login-form-container .login-button:hover {
    background-color:  #004080;
}

.search-container {
    text-align: center;
    margin-top: 20px;
}

.search-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.search-container .search-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.search-container .search-button:hover {
    background-color: #0056b3;
}

.signup-link {
    text-align: center;
    margin-top: 20px;
}

/* Change the navbar color to blue */
.w3-bar.w3-theme-d2 {
    background-color: #007bff;
}

/* Change the navbar color to blue */


/* Change the background color of the work section to light blue (#ADD8E6) */
.w3-bar.w3-blue {
    background-color: #ADD8E6;
}

.or-text p {
    text-align: center;
}

.login-a {
    color: white;
}


.or-text {
    margin: 10px 0; /* Adjust the margin as needed */
    color: red; 
}


.login-form-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 17px;
}

/* Style for the dropdown arrow */
.login-form-container select:after {
    content: '\25BC'; /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none; /* Disable click on the arrow */
}

/* Style for the dropdown arrow in Firefox */
.login-form-container select::-ms-expand {
    display: none;
}