.career-book-container {
    text-align: center;
    margin: 20px auto; /* Auto margins to horizontally center */
  }
  
  .button-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons horizontally */
  }
  
  .button-container button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
  }
  