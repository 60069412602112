.list-display-container {
	// Container styles
	background-color: var(--c-ash);
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.list-display {
		// Card container styles specific to the list display component
		&__cards {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;
			gap: 2.5rem;
			width: 90%;
			max-width: 1000px;
			margin: 10vh auto;
		}

		&__card {
			border-radius: 16px;
			box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
			width: 450px;
			background-color: var(--c-white);
			padding: 1.5rem;
			border: 1px solid black;
			margin: 20px;

			&__title {
				font-size: 2rem;
				color: var(--c-void);
				line-height: 1.25;
				margin-top: 0;
				margin-bottom: 1rem;
			}

			&__info {
				color: var(--c-charcoal);
				font-size: 1rem;
				margin-bottom: 1.25rem;
			}

			&__button {
				font: inherit;
				line-height: 1;
				background-color: var(--c-grey);
				border: 2px solid var(--c-grey);
				color: black;
				padding: 0.5em 1em;
				border-radius: 6px;
				font-weight: 500;
				display: inline-flex;
				align-items: center;
				justify-content: space-between;
				gap: 0.5rem;
				margin: 5px;
				width: 100%;

				&:hover,
				&:focus {
					background-color: var(--c-grey);
					color: var(--c-white);
				}
			}
		}
	}
}
