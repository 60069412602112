.container_water{
    background-color:rgb(32, 133, 133);
    height: auto;
    width: auto;
    display: grid;
    grid-template: .4fr .2fr 1fr 4fr  / 1fr 2fr 5fr 5fr  ;
    /* grid-template-rows: 1fr 1fr 2fr 4fr;         // this  code is repalce by above
    grid-template-columns: 5fr 5fr;                 // this  code is repalce by above */    
    column-gap: 10px;
    margin: 20px 5% 5px 5.5%;
}
    
.logo_water{

    background-color:rgb(73, 154, 154);
    font-weight: bolder;
    font-size: 50px;
    grid-row: 1;
    grid-column: 1/-1;
    border-radius: 10px ;
    border-bottom: 5px solid rgb(72, 180, 180);
    color: white;
    margin-top: 5px;
    text-shadow: 5px 5px #69b47a;
}

.header_water{
    background-color:rgb(32, 133, 133);
    grid-row: 2;
    grid-column: 1/-1;
    word-break: 5px 10px ;
    color: white;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-top: 30px;
}

.testbase{
    background-color: whitesmoke;
    grid-row: 3;
    grid-column: 1/3;
    border-radius: 10px ;
    margin-top: 30px;
    border: 3px solid rgb(72, 180, 180);
    
}
.testadv{
    background-color:whitesmoke;
    grid-row: 3,4;
    grid-column: 1/3;
    border-radius: 10px ;
    border: 3px solid rgb(72, 180, 180);
    margin-top: 30px;  
}
.content_water{
    background-color:rgb(72, 180, 180);
    grid-row: 3/-1;
    grid-column: 3/-1;
    border-radius: 10px ;
    font-size: 50px;
    text-align: center;
    color:white;
    font-weight:bolder;
    margin-top: 30px;
    border: 3px solid rgb(72, 180, 180);
}
@media screen and (max-width: 768px) {
    .logo_water{
        grid-row: 1;
        grid-column: 1/-1;
    }
    .header_water{
        grid-row: 2;
        grid-column: 1/-1;
    }
    .testbase{
        grid-row: 3;
        grid-column: 1/-1;
    }
    .testadv{
        grid-row: 4;
        grid-column: 1/-1; 
    }
    .content_water{
        grid-row: 5/-1;
        grid-column: 1/-1;
}

}

.input_water{
    background-color:aliceblue;
    height:30px;
    width:60%;
    margin-left: 20%;
    border-radius: 10px;  
 }

    .button_water{
            
        font-size: 15px;
        margin-top:20px;
        width: 30%;
        margin-left: 35%;
        background-color:rgb(28, 154, 154);
        color: white;
        text-align: center;
        border-radius: 10px; 
        
        margin-bottom:10px;
        
          }
        .button_water  h2{
            text-align: center;
            color:red;
            
            font-weight:bolder;
          }

.center_water{
    text-align: center;
}

.circle{

    height: 250px;
    width: 250px;
    margin-top: 60px;
    background-color: orange;
    border-radius: 50%;
    display: inline-block;
     text-align: center;
}

.greet{

    display: inline-block;
    align-items: left ;
    font-weight:300;
    font-size: 25px;
    
}

.info{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: left ;
    font-weight:300;
    font-size: 25px;
}

.container_water .chart {
    width: auto;
    height: auto;
    justify-content: center;
  }

.hi{

    display: flex;
    justify-content: center;
}

.base{
    text-align: center;
}
  