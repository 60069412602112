.aboutus-container {
    max-width: 800px; /* Adjust the width as needed */
    margin: 0 auto; /* Center the content horizontally */
    margin-left: 280px;
  }

  .aboutus-container table {
    border: none ;
  }
  
  .aboutus-container td {
    border: none ;
  }

  
 .aboutus-container p {
  text-align: justify;
}