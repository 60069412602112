/* HospitalForm.css */
.hospital1-form {
    width: 500px;
    height: 1200px;
    margin: 50px auto; /* Center the form */
    padding: 20px; /* Add padding for better visual */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  }
  
  .form-label {
    display: block;
    margin-bottom: 10px;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  .form-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
.form-label .form-textarea {
  width: 100%;
  min-height: 50px; /* Adjust the initial height as needed */
  padding: 8px;
  box-sizing: border-box;
  resize: vertical; /* Allow vertical resizing */
}

/* Add this CSS in your stylesheet or in a style block in your component */

.hospital-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Adjust the margin as needed */
}

.hospital-table th, .hospital-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.hospital-table th {
  background-color: #f2f2f2;
}

.hospital-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.hospital-table tbody tr:hover {
  background-color: #ddd;
}

.button-container {
  display: flex;
  gap: 10px; /* Adjust the gap between buttons as needed */
}


.edit-button {
  /* Add your styles for the Edit button here */
  background-color: #51d755; /* Green */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button {
  /* Add your styles for the Delete button here */
  background-color: #f22e20; /* Red */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.edit-button:hover{
  background-color: #45a049; /* Darker green on hover */
}
.delete-button:hover {
  background-color: #9c180f; /* Darker green on hover */
}

.edit-button:active,
.delete-button:active {
  transform: scale(0.95); /* Slight scale down on click */
}




