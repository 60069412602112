.view-history-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px; /* Added rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
}

.view-history-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

.email-group {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 15px;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform for subtle scaling effect */
    cursor: pointer;
}

.email-group:hover {
    background-color: #f0f0f0;
    transform: scale(1.02); /* Subtle scale on hover */
}

.email-group h3 {
    font-size: 1.2rem;
    color: #555;
    margin: 0; /* Removed default margin for consistency */
}

.email-group p {
    color: #888;
    font-size: 0.9rem;
    margin-top: 5px;
}

.email-details-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.email-details-container h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

.email-details-container p {
    font-size: 1rem;
    color: #555;
}

.email-details-container strong {
    color: #333;
}

.email-group-title {
    font-size: 1.1rem; /* Slightly smaller size for a cleaner look */
    font-weight: 600; /* Make it bold for emphasis */
    color: #333;
    margin: 0; /* Ensure no additional margin for consistency */
}

.email-group-count {
    font-size: 0.9rem; /* Smaller size for the email count */
    color: #777; /* A lighter shade for the count text */
    margin: 5px 0; /* Space above and below the count */
}


/* Added a responsive design tweak */
@media (max-width: 600px) {
    .view-history-container {
        padding: 15px;
    }

    .email-group h3 {
        font-size: 1.1rem;
    }

    .email-details-container h2 {
        font-size: 1.3rem;
    }
}
