.pagelog-home {
    overflow-x: auto;
    
    margin: 0px 175px;
}
  .pagelog-home .pagelog-w1-table  {
    border: 1px solid #ddd  !important;
    border-collapse: collapse;
    width: 1500px;
  }
  
  .pagelog-home .pagelog-w1-table th, .pagelog-home .pagelog-w1-table td {
    border: 1px solid #ddd  !important;
    padding: 10px; 
    text-align: center;
    width: 1000px;
  }

  .pagelog-home .pagelog-w1-table thead {
    background-color: #9d2f2f;
}


@media only screen and (max-width: 600px) {
  /* Apply styles only for screens less than or equal to 600px wide (typical mobile devices) */
  .pagelog-home {
      margin: 0px; /* Remove horizontal margin for mobile view */
  }

  .pagelog-home .pagelog-w1-table {
      width: 100%; /* Make table width 100% for mobile view */
  }

  .pagelog-home .pagelog-w1-table th, .pagelog-home .pagelog-w1-table td {
      padding: 6px; /* Adjust padding for mobile view */
  }
}
  