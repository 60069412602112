.spotlight-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100vh;  */
}

.horizontal {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border: 1px solid #ccc; Remove this line */
}

.horizontal:first-child {
  flex: 80%; /* Adjust as needed */
}

.horizontal:nth-child(2) {
  flex: 20%; /* Adjust as needed */
}

.vertical {
  flex: 10%;
  display: flex;
  flex-direction: column;
  padding: 80px;
  /* border-right: 1px solid #ccc; Remove this line */
  margin-top: -60px;
}

.vertical3 {
  flex: 10%;
  display: flex; /* Ensure all links start from the same position */
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
  padding: 80px; /* Adjust as needed */
  border-right: 1px solid #ccc; /* Add border to vertically separate sections */
  margin-top: -100px;
}

.vertical a {
  text-decoration: none; /* Remove underline from links */
  color: #8B4513; /* Set link color to brown */
  margin-bottom: 5px; /* Adjust spacing between links */
  display: block; /* Ensure each link starts on a new line */
}

.vertical.center {
  flex: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-right: 1px solid #ccc; /* Add border to vertically separate sections */
}

.vertical .about {
  text-align: center;
  margin-top: 1px;
}

.vertical h1 {
  color: #8B4513; /* Set h1 color to brown */
}

.vertical p {
  margin: 0; /* Remove default margin for paragraphs */
}

.photo {
  width: 100%; 
  height: 80%;
  max-height: 120px; 
  /* padding: 80px; */
}

.vertical3 .company-info {
   /* Adjust as needed */
 
  display: flex; /* Adjust to make it flex column */
  flex-direction: column; 
 
}

.vertical .company-info p {
  margin: 5px 0; /* Adjust spacing between paragraphs */
  
}
.vertical3 .company-info p {
  color:black; /* Adjust spacing between paragraphs */
  background-color: paleturquoise;
  
}
.spotlight-heading img {
  max-width: 100px; /* Set the maximum width for the logo */
  max-height: 100px;
}
.spotlight-heading p{
  color:violet;
}
.vertical button {
  margin-bottom: 10px;
  padding: 7px 20px;
  border: none;
  border-radius: 4px;
  background-color: rgb(137, 212, 196);
  color: #0d0808;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  white-space: nowrap;
}

.vertical button:hover {
  background-color: #999;
}
.spotlight-heading {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.logo-container {
  margin-right: 20px; /* Adjust spacing between logo and text */
}

.logo-container .logo {
  max-width: 100px; /* Set maximum width for the logo */
  max-height: 100px;
}

.interview-details {
  display: flex;
  flex-direction: column;
}

.interview-details h1 {
  margin: 0;
  color: rgb(23, 23, 182);
}

.interview-details p {
  margin-top: 5px; /* Adjust spacing */
}


/* Add more specific styles or adjust as needed */
/* Layout.css */

.no-bullets {
  list-style-type: none;
}

.h6-css{
  text-align: left;
  text-justify: inter-word;
}