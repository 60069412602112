.archives-link {
    
  margin-top: 17px; 
}


.vendor .content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
  }
  
  .vendor .box {
    background-color: #fff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 4%), 0 0 2px rgb(0 0 0 / 6%), 0 0 1px rgb(0 0 0 / 4%);
  }
  .vendor .ima {
    width: 150px;
    height: 100px;
  }
  .vendor img {
    width: 160px;
    height: 100px;
    object-fit: cover;
  }
  .vendor h3 {
    padding: 10px;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    .vendor .content {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

