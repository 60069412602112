/* Tapovan SWOT Analysis CSS */

/* Container styles */
.swot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f7f9fc;
    padding: 40px 20px;
    min-height: 100vh;
  }
  
  .swot-container.swot-title {
    font-size: 48px;
    color: #2980b9;
    font-weight: 700;
    margin-bottom: 40px;
    text-transform: capitalize;
    letter-spacing: 2px;
    text-align: center;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  }
  
  .swot-container.section-heading {
    font-size: 32px;
    color: #34495e;
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    border-bottom: 2px solid #2980b9;
    padding-bottom: 10px;
  }
  
  .swot-container.swot-section {
    width: 100%;
    max-width: 900px;
    background-color: #ffffff;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .swot-container.swot-section:hover {
    transform: translateY(-5px);
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  }
  
  .swot-container.swot-item {
    margin-bottom: 15px;
  }
  
  .swot-container.swot-item:last-child {
    margin-bottom: 0;
  }
  
  .swot-container.swot-subtitle {
    font-size: 24px;
    color: #2c3e50;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  
  .swot-container ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .swot-container ul li {
    font-size: 18px;
    color: #7f8c8d;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .swot-container ul li span {
    font-weight: 600;
    color: #2980b9;
  }
  
  .swot-container.swot-text {
    font-size: 18px;
    color: #2c3e50;
    line-height: 1.8;
  }
  
  .swot-container.swot-text b,
  .swot-container.swot-text strong {
    color: #2980b9;
  }
  
  .swot-container.swot-text ul {
    margin-top: 10px;
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .swot-container.swot-text ul li {
    margin-bottom: 8px;
  }


  /* Spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #2980b9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

  
  /* Responsive Design */
  @media (max-width: 768px) {
   .swot-container {
      padding: 20px 15px;
    }
   .swot-container.swot-title {
      font-size: 36px;
      margin-bottom: 30px;
    }
   .swot-container.section-heading {
      font-size: 28px;
      margin-bottom: 15px;
    }
   .swot-container.swot-section {
      padding: 20px;
    }
   .swot-container.swot-subtitle {
      font-size: 22px;
    }
   .swot-container ul li {
      font-size: 16px;
    }
  }
  