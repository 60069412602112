.container-watermark {
    padding: 20px;
    text-align: center;
    max-width: 1320px;
    margin: 0 auto;
   
  }
  
  .image-container-watermark {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px; /* Increased gap for better spacing */
  }
  
  .image-link-watermark {
    display: inline-block;
    text-decoration: none; /* Remove default underline */
    border: 2px solid transparent; /* Initial border style */
    transition: border-color 0.3s, transform 0.3s; /* Smooth transitions */
  }
  
  .image-link-watermark:hover {
    border-color: #007bff; /* Blue border on hover */
    transform: scale(1.05); /* Slight zoom effect */
  }
  
  .image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s; /* Smooth fade effect */
  }
  
  .image-link:hover .image {
    opacity: 0.9; /* Slight fade effect on hover */
  }
