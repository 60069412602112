/* Desktop Styles */
.tools-container-wrapper {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1320px;
  }
  
  .tools-container-wrapper h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
  }
  
  .tools-container-wrapper .tools-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .tools-container-wrapper .tools-list .tools-item {
    padding: 12px 16px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    transition: box-shadow 0.3s ease, color 0.3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .tools-container-wrapper .tools-list .tools-item:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    color: #0078d7;
  }
  
  .tools-container-wrapper .tools-list .tools-item:hover::after {
    content: "→";
    font-size: 1.2rem;
    margin-left: 8px;
    color: #0078d7;
    transition: color 0.3s ease;
  }
  
  .tools-container-wrapper .tools-list .tools-item strong {
    font-size: 1.2rem;
    color: inherit;
  }
  
  .tools-container-wrapper .tools-list .tools-item span {
    font-size: 1rem;
    color: #555;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .tools-container-wrapper {
      padding: 12px;
      max-width: 100%; /* Adjust to use full screen width */
    }
  
    .tools-container-wrapper h3 {
      font-size: 1.4rem;
      text-align: center;
      margin-bottom: 12px;
    }
  
    .tools-container-wrapper .tools-list .tools-item {
      padding: 10px;
      margin: 6px 0;
      flex-direction: column; /* Stack content vertically for narrow screens */
      align-items: flex-start;
    }
  
    .tools-container-wrapper .tools-list .tools-item:hover::after {
      margin-left: 0; /* Align the arrow better for stacked content */
      margin-top: 4px;
    }
  
    .tools-container-wrapper .tools-list .tools-item strong {
      font-size: 1rem;
    }
  
    .tools-container-wrapper .tools-list .tools-item span {
      font-size: 0.9rem;
      color: #666;
    }
  
    .tools-container-wrapper .tools-list .tools-item:hover {
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    }
  }
  