/* Overall container */
.details-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
     max-width: 1320px;
  }
  
  /* Box styling */
  .details-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Logo styling */
  .details-logo {
    display: block;
    max-width: 150px;
    margin: 0 auto 20px auto;
  }
  
  /* Headings */
  .details-heading {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Section styling */
  .details-section {
    margin-bottom: 20px;
  }
  
  /* Labels */
  .details-label {
    font-weight: bold;
    font-size: 16px;
    color: #444;
    margin-bottom: 5px;
  }
  
  /* Text */
  .details-text {
    font-size: 14px;
    color: #666;
  }
  
  /* Links */
  .details-text a {
    color: #0073e6;
    text-decoration: none;
  }
  
  .details-text a:hover {
    text-decoration: underline;
  }
  

    /* Simple CSS spinner */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-circle {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
