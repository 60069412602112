.competitors-container {
  font-family: 'Arial', sans-serif;
  max-width: 1320px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.competitors-container h3 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.fetch-competitors, .add-competitor-form {
  margin-bottom: 30px;
}

.fetch-competitors input, .add-competitor-form input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
}

.fetch-competitors button, .add-competitor-form button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fetch-competitors button:hover, .add-competitor-form button:hover {
  background-color: #0056b3;
}

.fetch-competitors ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.fetch-competitors ul li {
  background: #e6f7ff;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #b3e5fc;
  border-radius: 5px;
  font-size: 14px;
}

.add-competitor-form {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.add-competitor-form label {
  font-weight: bold;
  color: #555;
  display: block;
  margin-bottom: 5px;
}

.error {
  color: #ff4d4f;
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  text-align: center;
}

.success {
  color: #52c41a;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  text-align: center;
}

.competitor-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.competitor-item button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.competitor-item button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .competitors-container {
      padding: 15px;
  }

  .fetch-competitors button, .add-competitor-form button {
      width: 100%;
      padding: 12px;
  }

  .fetch-competitors input, .add-competitor-form input {
      font-size: 16px;
  }

  .add-competitor-form {
      padding-top: 15px;
  }

  .add-competitor-form label {
      font-size: 16px;
  }

  .fetch-competitors ul li {
      font-size: 16px;
  }

  .competitor-item {
      font-size: 16px;
      padding: 12px;
  }
}

@media (max-width: 480px) {
  .competitors-container {
      padding: 10px;
  }

  .fetch-competitors button, .add-competitor-form button {
      padding: 15px;
  }

  .fetch-competitors input, .add-competitor-form input {
      font-size: 18px;
      padding: 12px;
  }

  .competitors-container h3 {
      font-size: 22px;
  }

  .add-competitor-form label {
      font-size: 18px;
  }

  .competitor-item {
      font-size: 18px;
      padding: 15px;
  }

  .error, .success {
      font-size: 16px;
  }
}
