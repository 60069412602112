.add-data-form-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-data-form-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .input-field {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .search-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  
  .vendor-link {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .vendor-link:hover {
    text-decoration: underline;
  }
  
  .results-list {
    list-style-type: none;
    padding: 0;
  }
  
  .result-item {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .vendor-info {
    margin: 5px 0;
    font-size: 0.95em;
  }
  
  .vendor-info strong {
    color: #333;
  }


  .add-comments-button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    text-align: center;
  }
  
  .add-comments-button:hover {
    background-color: #218838;
  }

  .search-button:disabled,
.add-comments-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

  
  