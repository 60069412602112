/* Home.css */

/* Apply background image styles to the container */
.health-container {
    margin: 0;
    padding: 0;
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url("./images/background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    
  }
  
  /* Style the navigation links */
   .health-nav {
    display: flex;
    justify-content: flex-start;
    align-items: top;
    height: 50px;
    background-color: transparent;
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 30px;
  } 
  
  .health-nav a {
    text-decoration: none;
    color: #200cd6;
    margin-left: 20px;
    font-size: 25px;
  }
  
  /* Content styles */
  .health-content {
    margin-left: 210px;
    padding: 30px;
    z-index: 2;
  }
  