.content-container-familygp {
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px;
 
    overflow-y: auto; 
  }


  .img-circle {
    border-radius: 50%;
}



body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  
   
  .glyphicon {
    font-size: 20px;
  }
  
  /* h2, h6 {
    display: inline;
  } */

  .form-group .form-control {
    width: 200px; 
  }


  .navbar-form-familygp {
    padding: 10px; 
    margin-right: -10px; 
    margin-left: -10px; 
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
  
   
    display: flex;
    flex-wrap: wrap;
  }
  
  .navbar-form-familygp  .form-group {
    margin-bottom: 5px;
    height: 35px; 
  }

 
  
  @media (max-width: 768px) { 
    
    .navbar-form-familygp  .form-group:last-child {
      margin-bottom: 0;
    }
  }


  .navbar-form-familygp .btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.navbar-form-familygp .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid ;
    padding: 6px 10px; 
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;
}

.navbar-form-familygp .btn:hover {
    text-decoration: none;
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}


.open-button {
    background-color: #117FF3;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 18px;
    width: 70px;
    position: relative;
    float: right;
    left: 0;
    bottom: 0;
    border-radius: 50%;
    padding: 10px;
}

.content-container-familygp {
    position: relative;
  
}



.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
}
.popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 300px;
    height: 400px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.popup .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: bold;
    outline: none;
}

.open-button {
 
}




.popup .form-group {
    margin-bottom: 10px;
}

.popup label {
    margin-bottom: 5px;
}

.popup input {
  
}



.popup .btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.popup .btn {
    display: flex;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid ;
    padding: 6px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;
}

.popup .btn:hover {
    text-decoration: none;
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}


.popup .submit-button-div {
    margin-top: 20px; 
    align-items: center;
}

.popup .submit-button-div button {
    margin: 0 auto; 
}

.custom-hr {
  border-top: 1px solid #ccc; 
} 

.notification {
    position: relative;
    display: inline-block;
    float: right;
    margin-right: 20px; 
    margin-top: 20px; 
    padding: 8px 16px;
    background-color: #555;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.notification:hover {
    background-color: #333;
}

.notification .badge {
    position: absolute;
    top: -6px; /* Adjust position to ensure it's aligned properly */
    right: -6px; /* Adjust position to ensure it's aligned properly */
    width: 16px; /* Make the badge smaller */
    height: 16px; /* Ensure the badge is a circle */
    padding: 0;
    border-radius: 50%; /* Make the badge circular */
    background-color: red;
    color: white;
    font-size: 10px; /* Smaller font size for the "0" */
    font-weight: bold;
    line-height: 16px; /* Center the text vertically within the circle */
    text-align: center; /* Center the text horizontally */
    /* border: 2px solid white;  */
    box-sizing: border-box; /* Ensure padding and borders are considered in size */
    transition: background-color 0.3s ease;
}

.notification .badge:empty::before {
    content: "0";
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.notification .inbox {
    margin: 0;
    padding: 0;
    font-size: 14px;  /* Adjust to the desired size */
    line-height: 1.2;  /* Adjust line height if needed */
}