/* Point.css */

/* Container styling */
.point-container {
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  max-width: 80%;
  margin-left: 15%;
  /* position:fixed; */
  display: flex;
  flex-direction: column;
  overflow-x: auto;

}

/* Total points styling */
.total-points {
  font-size: 24px;
  margin-bottom: 20px;
}

/* More Details button styling */
.more-details-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin: 10px;
  transition: background-color 0.3s;
}

.more-details-button:hover {
  background-color: #0056b3;
}

/* Table styling */
.point-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #fff;
}

/* Table header styling */
.point-table th {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

/* Table cell styling */
.point-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

/* Highlighted row styling */
.point-table tr.highlight {
  background-color: #f5f5f5;
}

/* Loading message styling */
.loading-message {
  font-size: 20px;
  margin: 20px;
  color: #777;
}

/* Link styling */
.more-details-link {
  text-decoration: none;
  color: #007bff;
}

.more-details-link:hover {
  text-decoration: underline;
}