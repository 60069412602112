/* 

.myApp-container {
    display: flex;
    margin-left: 300px; 
  }
  
  .business-link-box {
    background-color: #e0eaf0; 
  width: 30%; 
  padding: 20px 20px 20px 40px; 
  text-align: left; 
  border-radius: 10px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
 
  max-height:2000px;
    
  }
  
  .myApp-content-container {
    flex: 2; 
    padding: 10px;
    margin-left: 200px; 
  }

  .myApp-link {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    display: block; 
    margin-bottom: 10px; 
  } */

  .business-container {
    display: flex;
    margin-left: 300px;
  }
  
  .business-link-box {
    background-color: #e0eaf0;
    width: 100%;
    padding: 20px 20px 20px 40px;
    text-align: left;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height:1700px;
    align-items: center; 
  }
  
  .business-content-container {
    flex: 1;
    padding: 10px;
    margin-left: 20%;
    width: 50%;
  }
  .business-content-container {
    flex: 1;
    padding: 10px;
    margin-left: 250px;
    
  }
  
  
  .business-link {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
    
  }

  .back-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust the height based on your design */
  }