/* act.css */

.active-portal-container {
  padding: 20px;
}

.active-portal-heading {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #4e69ac;
  color: #fff;
  text-align: center;
}


.active-portal-content {
  display: flex;
  flex-direction: column;
}
.active-portal-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.active-portal-column h2 {
  background-color: #4e69ac;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
}


.active-portal-table {
  width: 100%;
  border-collapse: collapse;
}

.active-portal-table th,
.active-portal-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ccc;
  vertical-align: top;
}

.active-portal-table th {
  font-weight: bold;
}

.active-portal-table tr:nth-child(even) {
  background-color: #1427d6;
}

.active-portal-table tr:hover {
  background-color: #1034d3;
}

.active-portal-table th:first-child,
.active-portal-table td:first-child {
  border-left: none;
}

.active-portal-table th:last-child,
.active-portal-table td:last-child {
  border-right: none;
}

.active-portal-table thead th:first-child,
.active-portal-table tbody td:first-child {
  border-top-left-radius: 4px;
}

.active-portal-table thead th:last-child,
.active-portal-table tbody td:last-child {
  border-top-right-radius: 4px;
}

.active-portal-table tbody td::before {
  content: attr(data-heading);
  font-weight: bold;
  background-color: #4e69ac;
  display: block;
  margin-bottom: 5px;
}

.active-portal-table li {
  list-style-type: none;
}

.active-portal-table th:nth-child(1),
.active-portal-table td:nth-child(1) {
  background-color: #4e69ac;
  color: #fff;
}

.active-portal-table th:nth-child(2),
.active-portal-table td:nth-child(2) {
  background-color: #4e69ac;
  color: #fff;
}

.active-portal-table th:nth-child(3),
.active-portal-table td:nth-child(3) {
  background-color: #4e69ac;
  color: #fff;
}

.active-portal-table th:nth-child(4),
.active-portal-table td:nth-child(4) {
  background-color: #4e69ac;
  color: #fff;
}

.active-portal-table th:nth-child(5),
.active-portal-table td:nth-child(5) {
  background-color: #4e69ac;
  color: #fff;
}

.active-portal-table th:nth-child(6),
.active-portal-table td:nth-child(6) {
  background-color: #4e69ac;
  color: #fff;
}
