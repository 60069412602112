.techieindex-activate-form-container {
    max-width: 600px; 
    margin: 1px auto 0; 
    background-color: #fff; /* Set a background color */
    padding: 20px; /* Add padding to the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for a card-like effect */
    border-radius: 10px; /* Add rounded corners */
    /* padding-top: 20px; */
    min-height: 400px;
    /* height: 600px; */


    
}