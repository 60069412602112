
.vendor_extra .content_extra {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
 
  left: 10%;
  top: 80%;
  transform: translate(-33%, -10%);
}

  .vendor_extra .content_extra > * {
    font-size: 14px;
  }
  .vendor_extra .box {
    background-color: #fff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 4%), 0 0 2px rgb(0 0 0 / 6%), 0 0 1px rgb(0 0 0 / 4%);
  }
  .vendor_extra .ima {
    width: 150px;
    height: 100px;
  }
  .vendor_extra img {
    width: 160px;
    height: 100px;
    object-fit: cover;
  }
  .vendor_extra h3 {
    padding: 10px;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    .vendor_extra .content_extra {
      grid-template-columns: repeat(2, 1fr);
    }
  }