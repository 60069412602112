.sent-emails-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sent-emails-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

.email-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.email-item p {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
}
