/* 
  .table-container-editprofile {
    padding: 20px;
  }

  .editaccountprofile-container {
    max-width: 600px;
    margin: 1px auto 0;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: 400px;
  }
  
  .editaccountprofile-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #000 !important;
  }
  
  .editaccountprofile-table .editaccountprofile-th,
  .editaccountprofile-table td {
    padding: 20px !important;  
      background-color: #ffffff !important;
      border: 1px solid #000 !important;
    
  }
  
 
  
  .profile-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .profile-button:hover {
    background-color: #0056b3;
  }


  input[type='text'] {
   
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.centered-input {
  width: 300px;
  margin: 0 auto;
}


.edit-profile-container-account {
  
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 

  width: 700px;
  min-height: 600px;
}

@media screen and (max-width: 768px) {


  .edit-profile-container-account {
   
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
 
    width: 450px;
    min-height: 800px;
  }

  .edit-profile-form{
    margin: 100px auto;
  }

}


.template-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; 
}

.template-modal {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
} */

.edit-profile-container-account {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-height: 600px;
}

.edit-profile-form {
  width: 100%;
}

.profile-info-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.profile-info-row label {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
  min-width: 150px; /* Ensure all labels have the same width */

}



.profile-info-row div,

.profile-info-row a {

  flex: 1;

  white-space: nowrap; /* Prevent line breaks within links */
  /* line-height: 5; */
  margin-top: -7px;

}

.centered-input {
  width: 300px;
  margin: 0 auto;
}

input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.profile-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.profile-button:hover {
  background-color: #0056b3;
}

.template-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.template-modal {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .edit-profile-container-account {
    width: 450px;
    min-height: 800px;
  }
}

.table-container-editprofile {
  padding: 20px;
}

.editaccountprofile-container {
  max-width: 600px;
  margin: 1px auto 0;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 400px;
}

.editaccountprofile-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #000 !important;
}

.editaccountprofile-table .editaccountprofile-th,
.editaccountprofile-table td {
  padding: 20px !important;
  background-color: #ffffff !important;
  border: 1px solid #000 !important;
}
