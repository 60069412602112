.batch-mode-ml-gen {
    /* General layout and font styling */
    font-family: Arial, sans-serif;
    background-color: #f0f4f8;
    margin: 0;
    padding: 0;
  }
  
  .batch-mode-ml-gen .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .batch-mode-ml-gen header {
    background-color: #4CAF50;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    color: white;
    font-size: 2rem;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .batch-mode-ml-gen #chatbox {
    display: flex;
    width: 100%;
    gap: 20px;
  }
  
  .batch-mode-ml-gen .left-window {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .batch-mode-ml-gen .right-window {
    flex: 1.5;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .batch-mode-ml-gen h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .batch-mode-ml-gen input[type="text"],
  .batch-mode-ml-gen input[type="number"] {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .batch-mode-ml-gen button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s;
  }
  
  .batch-mode-ml-gen button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .batch-mode-ml-gen button:hover:not(:disabled) {
    background-color: #45a049;
  }
  


  /* .batch-mode-ml-gen.messages .message-llm {
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 10px;
  }
  
  .batch-mode-ml-gen.messages .message-llm .success-llm {
    background-color: #d4edda;
    border-left: 5px solid #28a745;
  }
  
  .batch-mode-ml-gen.messages .message-llm .error-llm {
    background-color: #f8d7da;
    border-left: 5px solid #dc3545;
  } */

  /* styles.css */
.message-window {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.message-llm {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.success-llm {
  background-color: #d4edda;
  border-color: #28a745;
  color: #155724;
}

.error-llm {
  background-color: #f8d7da;
  border-color: #dc3545;
  color: #721c24;
}

  
  
  .batch-mode-ml-gen .code-output {
    background-color: #282c34;
    color: #f8f8f2;
    padding: 20px;
    border-radius: 5px;
    font-family: monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .batch-mode-ml-gen footer {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  
  .batch-mode-ml-gen .selected-disease {
    background-color: #d4edda; /* Light green background similar to success message */
    color: #155724; /* Dark green text for contrast */
    border-left: 5px solid #28a745; /* Green left border */
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;
    font-size: 1.25rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    text-align: center; /* Center the text */
    font-family: "Arial", sans-serif; /* Set the font family to match Input Disease Name */
  }
  
  .batch-mode-ml-gen .selected-disease h4 {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove padding */
    font-size: 1.5rem;
    color: #155724;
    font-family: "Arial", sans-serif; /* Set the same font family for h4 */
    font-weight: 600; /* Similar to h3 weight */
  }
  

  .batch-mode-ml-gen .copy-button {
    background-color: #28a745; /* Blue background */
    color: white;
    padding: 8px 12px; /* Slightly smaller padding */
    border: none;
    border-radius: 5px;
    font-size: 0.9rem; /* Slightly smaller font size */
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s;
  }
  
  .batch-mode-ml-gen .copy-button:hover {
    background-color: #039BE5; /* Darker blue on hover */
  }
  
  .batch-mode-ml-gen .copy-button:disabled {
    background-color: #ccc; /* Gray background when disabled */
    cursor: not-allowed;
  }
  

  .batch-mode-ml-gen.right-window {
    display: flex;
    flex-direction: column;
  }
  
  .batch-mode-ml-gen.code-window,
  .batch-mode-ml-gen.message-window {
    margin: 0;
    padding: 0;
  }


  .template-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's on top of other content */
  }
  
  .template-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px; /* Adjust the width as needed */
    width: 100%;
    text-align: center;
  }

  .batch-mode-ml-gen .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); /* White background with 70% opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other content */
  }
  
  .batch-mode-ml-gen .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1); /* Light gray border */
    border-top: 8px solid #3498db; /* Blue top border */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }

  /* Example to highlight the selected label */
/* input[type="radio"]:checked + label {
  font-weight: bold; 
  color: #28a745; 
} */

.batch-mode-ml-gen input[type="radio"]:checked + label {
  font-weight: bold; /* or any other styles you prefer */
  color: #28a745; /* or any other color you prefer */
}


.batch-mode-ml-gen label {
  font-size: 1.25rem; /* Adjust the font size to your preference */
  font-weight: 500; /* Optionally add some font weight for emphasis */
  cursor: pointer; /* To maintain the pointer cursor effect on hover */
  margin-left: 5px; /* Optional, for a bit of spacing between radio button and label */
}



  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  /* Mobile-specific styles (apply when screen width is 768px or less) */
@media only screen and (max-width: 768px) {
  .batch-mode-ml-gen.container {
     padding: 10px; /* Reduce padding for smaller screens */
     max-width: 100%; /* Ensure container takes full width */
   }
 
  .batch-mode-ml-gen #chatbox {
     flex-direction: column; /* Stack windows vertically on mobile */
   }
 
  .batch-mode-ml-gen.left-window, 
  .batch-mode-ml-gen.right-window {
     flex: 1 0 100%; /* Make each window full-width on mobile */
     margin-bottom: 20px; /* Add margin between stacked windows */
   }
 
  .batch-mode-ml-gen.right-window.code-window, 
  .batch-mode-ml-gen.right-window.message-window {
     margin-bottom: 20px; /* Space between windows */
   }
 
  .batch-mode-ml-gen.button-group {
     flex-direction: column; /* Stack buttons vertically */
     align-items: flex-start; /* Align to the left */
   }
 
  .batch-mode-ml-gen.button-group div {
     margin-bottom: 10px; /* Space between buttons */
   }
 
  .batch-mode-ml-gen.button-group div label {
     font-size: 1.1rem; /* Slightly smaller font for mobile */
   }
 
  .batch-mode-ml-gen.code-output {
     max-height: 250px; /* Reduce max height for smaller screens */
   }
 
   /* Optionally, adjust the spinner overlay for mobile */
  .batch-mode-ml-gen.spinner-overlay {
     background: rgba(255, 255, 255, 0.9); /* Slightly more opaque for mobile */
   }
 }
 
  
  
  