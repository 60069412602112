/* AddedProbs1.css */

.kerala-container {
    margin-top: 20px;
    border: 3.5px solid rgb(5, 118, 33); /* Add border style here */
    padding: 15px; /* Optional: Add padding for better visual appearance */ 
    margin-right: 80px;
    margin-bottom: 30px;
    height:500px;
  }
  .kerala-container {
    position: relative;
  }
  
  .pagination-buttons {
    position: absolute;
    bottom: 10px; /* Adjust the value as needed */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
  }
  
  .pagination-buttons button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #007bff; /* Change the background color as needed */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-buttons button:disabled {
    background-color: #ccc; /* Change the background color for disabled state as needed */
    cursor: not-allowed;
  }
  .kerala-container h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .kerala-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .kerala-container li {
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #555;
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(5, 118, 33); /* Add border style here */
    padding: 2px; /* Optional: Add padding for better visual appearance */
  }
  
  .kerala-container li span {
    font-size: 0.8rem;
    color: #888;
    height: 1px;
  }
  /* Styles for the More button */
  .kerala-container li button {
    align-self: flex-end;/* Align to the right side */
    padding: 5px 10px; /* Adjust padding for better appearance */
    background-color: #00A9FF;
    margin-right: 20px;
  }
  