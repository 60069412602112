.bulk-email-compose{
    max-width: 600px; 
    margin: 1px auto 0; 
    background-color: #fff; /* Set a background color */
    padding: 20px; /* Add padding to the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for a card-like effect */
    border-radius: 10px; /* Add rounded corners */
    /* padding-top: 20px; */
    min-height: 600px;
    /* height: 600px; */
}

.ql-editor {
    min-height: 400px; /* Example: Set the minimum height for the editor content */
    max-height: 500px; /* Example: Set the maximum height for the editor content */
    overflow-y: auto; /* Add vertical scrollbar if content exceeds the maximum height */
  }

  .template-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's on top of other content */
  }
  
  .template-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px; /* Adjust the width as needed */
    width: 100%;
    text-align: center;
  }


  .bulk-email-compose .button-container {
    display: flex;
    justify-content: space-between; /* Distribute space between items */
    align-items: center; /* Vertically center items */
    margin-top: 10px; /* Adjust as needed */
  }
  
  
  .bulk-email-compose .right-buttons {
    display: flex;
  }

  .email-list-container {
    max-height: 100px; /* Set a max height for the container */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #ccc; /* Optional: Add a border for better visibility */
    padding: 10px; /* Optional: Add padding for aesthetics */
    border-radius: 5px; /* Optional: Add rounded corners */
}
