@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

h1 {
  font-size: 24px;
}

.input {
  margin-top: 10px;
}

.content-container-upload {
  max-width: 1320px;
  margin: 0 auto;
  padding: 20px;

  overflow-y: auto;
}


.pagination-upload .pageination-button[disabled] {
  background-color: #dddddd;
  /* Light gray */
  color: #aaaaaa;
  /* Dark gray */
  cursor: not-allowed;
  /* Show cursor as not-allowed */
}

.file-info-label {

  color: #000000;
  /* padding: 10px;  */
  border-radius: 5px;
  /* Add rounded corners */
  margin-top: 5px;
  /* Add margin top for spacing */
  white-space: nowrap;
}


.card {
  background: var(#757779);
  padding: 20px;
  margin-top: calc(4 * var(--size-bezel));
  border-radius: var(--size-radius);
  border: 3px solid var(--color-shadow, currentColor);
  box-shadow: .5rem .5rem 0 var(--color-shadow, currentColor);
  width: 100%;
  border-radius: 20px;

  &--accent {
    --color-background: #757779;
    --color-accent: white;
    color: #212121;
  }

  h3 {
    font-size: 22px;
    font-weight: 600;
    font-family: "IBM Plex Sans", sans-serif;
  }
}


.drop_box {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px solid var(--color-shadow, currentColor);
  border-radius: 20px;

  h4 {
    font-size: 24px;
    font-weight: 400;
    color: #2e2e2e;
    font-family: "IBM Plex Sans", sans-serif;
  }

  p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    font-family: "IBM Plex Sans", sans-serif;

  }
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
}

.image-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.image-item {
  position: relative;
}

.image-select {
  display: flex;
  flex-direction: row;
}


.button-blue {
  text-decoration: none;
  background-color: #005af0;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
  border-radius: 5px;

  &:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
  }

  &:disabled {
    background-color: #d1d1d1;
    color: #757779;
    outline: none;
  }
}

.button-delete {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(0, 132, 255, 0.5);
  color: white;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
  }
}

.upload-image-container {
  width: 100%;
  text-align: center;
}

.form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}

.caption-text {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 3px solid black;
  border-radius: 20px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
  font-family: "IBM Plex Sans", sans-serif;
}

.previous-post-table {
  h2 {
    font-family: "IBM Plex Sans", sans-serif;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border: 3px solid black;
    box-shadow: .5rem .5rem 0 black;
    border-radius: 20px;
  }

  th {
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    background-color: #005af0;
    font-family: "IBM Plex Sans";
  }

  td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    color: #000000;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }

  tr {
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.upload-icon {
  max-height: 140px;
  padding: 0 20px;
}

.upload-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.submit-button-container {
  text-align: center;
}

.disclaimer-text {
  font-style: italic;
  padding-bottom: 10px;
}

.story-buttons-container {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  gap: 200px;

}

.story-buttons-container .button-blue {
  width: 25%;
}

.error-text{
  font-size: 16px;
  color: red;
}