.clothing-page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa; /* Light gray background */
}

.page-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #007bff; /* Blue title */
}

.page-description {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555; /* Dark gray description */
}

.center-button {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Add margin bottom for spacing */
}

.btn-navigate, .btn-go-back {
    background-color: #28a745; /* Green button */
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.btn-navigate:hover, .btn-go-back:hover {
    background-color: #218838; /* Dark green hover */
}

.loading-message {
    font-size: 18px;
    color: #dc3545; /* Red loading message */
}

.account-list {
    list-style: none;
    padding: 0;
}

.account-item {
    margin-bottom: 20px;
    background-color: #fff; /* White background for account items */
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.account-url {
    font-weight: bold;
    color: #007bff; /* Blue URL */
}

.account-info {
    font-style: italic;
    color: #6c757d; /* Gray info */
}

.account-info.likes {
    color: #28a745; /* Green for likes */
}

.account-info.comments {
    color: #007bff; /* Blue for comments */
}

.account-info.shares {
    color: #dc3545; /* Red for shares */
}