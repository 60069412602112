.table-container {
  margin-top: 20px; /* Adjust as needed */
  width: 100%;
  max-width: 800px; /* Example maximum width for the table */
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.table-container table th,
.table-container table td {
  padding: 12px 15px;
  text-align: left;
}

/* Style for Labform component container */
/* Style for Labform component container */
.labform-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px;
}

/* Style for the table */
.labform-container table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.labform-container table th,
.labform-container table td {
  padding: 12px 15px;
  text-align: left;
}

.labform-container table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.labform-container table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.labform-container table tbody tr:hover {
  background-color: #e0e0e0;
}

button {
  margin-top: 20px; /* Increased margin-top for the button */
  padding: 12px 24px; /* Adjusted padding for the button */
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}
/* Updated styles for Labform component container */
.labform-and-history-container {
  display: flex;
  flex-direction: row; /* Display containers in a row */
  justify-content: space-between; /* Add space between containers */
  margin: 20px;
}

/* Updated styles for Labform component container */
.labform-container {
  flex-grow: 1; /* Allow the labform container to grow and take available space */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Updated styles for Result history container */
.result-history-container {
  flex-shrink: 0; /* Prevent the result history container from shrinking */
  margin-left: 20px; /* Add margin between labform and result history containers */
}

/* Style for Result history container */
.result-history-container {
  flex-shrink: 0; /* Prevent the result history container from shrinking */
  margin-left: 20px; /* Add margin between labform and result history containers */
  text-align: left;
  padding: 20px; /* Add padding for better spacing */
  border-radius: 8px; /* Add border radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  border: 1px solid #ddd; /* Add border */
  height: fit-content;
  margin-top: 240px;
}

.result-history-container p {
  font-size: 16px;
}

.result-history-container button {
  margin-top: 10px; /* Adjusted margin-top for the button */
  padding: 10px 20px; /* Adjusted padding for the button */
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF; /* Use your desired background color */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.result-history-container button:hover {
  background-color: #0056b3; /* Use your desired hover background color */
}

.history-lab-p{
margin-left: 5px;
}






.table-container {
  margin: 20px 0;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.submission-result {
  margin-top: 20px;
}

.submission-result p {
  margin: 0;
  padding: 5px 0;
}
