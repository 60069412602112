/* Existing Styles for Scheduler Form */
.scheduler-container {
    padding: 20px;
    max-width: 500px;
    margin: 30px auto;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.scheduler-container h2 {
    text-align: center;
    color: #333;
}

.scheduler-form {
    display: flex;
    flex-direction: column;
}

.scheduler-form .form-group {
    margin-bottom: 15px;
    max-height: 100px;
    overflow: hidden;
    transition: max-height 0.4s ease, opacity 0.4s ease;
}

.scheduler-form .form-group.hidden {
    max-height: 0;
    opacity: 0;
    margin-bottom: -10px;
}

.scheduler-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.scheduler-form select,
.scheduler-form input[type="date"] {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.scheduler-form .error-message {
    color: red;
    margin-bottom: 15px;
    font-weight: bold;
}

.scheduler-form button {
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.scheduler-form button:hover {
    background-color: darkblue;
}

/* New Styles for Watermark Configuration Message */
.watermark-config-link-container {
    margin-top: 20px;
    padding: 15px;
    background-color: #fff3cd; /* Soft yellow background */
    border: 1px solid #ffeeba; /* Light border */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    color: #856404; /* Darker yellow text */
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.watermark-config-link-container p {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    color: #856404;
}

.watermark-config-link {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff; /* Blue background for the link */
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.watermark-config-link:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.watermark-config-link:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Focus state */
}


.scheduler-form .form-group label.checkbox-label {
    display: flex;
    align-items: center; /* Vertically aligns the checkbox and text */
    gap: 8px; /* Adds spacing between the checkbox and text */
    font-size: 16px; /* Adjust font size for better readability */
}

.scheduler-form .form-group input[type="checkbox"] {
    transform: scale(1.2); /* Optional: Enlarges the checkbox */
    margin: 0; /* Removes extra margin around the checkbox */
}

/* Optional: Customize the label font size and other properties */
.scheduler-form .form-group label.checkbox-label {
    font-size: 16px; /* Adjust font size for better readability */
}


.scheduler-form .form-group input[type="checkbox"]:checked {
    background-color: blue; /* Change background color when checked */
    border-color: blue; /* Adjust border color when checked */
}

