.addleaders-form-container {
    max-width: 600px;
    margin: 1px auto 0;
    background-color: #fff;
    /* Set a background color */
    padding: 20px;
    /* Add padding to the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add a shadow for a card-like effect */
    border-radius: 10px;
    /* Add rounded corners */
    /* padding-top: 20px; */
    min-height: 400px;
    /* height: 600px; */
}


.addleaders-button {
    padding: 10px 20px;
    /* background-color: #007bff; */
    background-color: rgb(16, 58, 131);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.addleaders input {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
}

.addleaders-textarea {
    height: 400px;
    /* Set the desired height (e.g., 500px) */
    /* width: 550px;  */
    width: 100%;
    padding: 10px;
    /* Add some padding around the textarea */
    margin: 0 auto;
    /* Center the textarea horizontally */
    display: block;
    /* Ensures that the textarea is a block element */
    border-radius: 5px;
}