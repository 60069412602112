main .container{
    display: flex;
    justify-content: space-between;
    height: 80vh;
}
.mainContent{
    width: 70%;  
       
}
.sideContent{
    width: 30%; 
      
}
@media screen and (max-width:768px) {
    main .container{
        flex-direction: column;
    }
    .mainContent{
        width: 100%;      
    }
    .sideContent{
        width: 100%;     
}
}