

.interviewpage{
    display: flex;
    flex-direction: column ;
    margin: 2% 10% 10% 15%;
}
 .interviewpage img{
    width: 500px;
 }
 .interviewpage button{

        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 5px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 15px;
        margin: 4px 2px 2px 2px;
        cursor: pointer;
      
 }