.grouplistschedule-textarea {
    height: 500px; /* Set the desired height (e.g., 500px) */
    width: 1200px; /* Set the desired width (e.g., 800px) */
    padding: 10px; /* Add some padding around the textarea */
    margin: 0 auto 0 0;

  display: block; /* Ensures that the textarea is a block element */
  }

  
  .grouplistschedule-container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 20px;
  }