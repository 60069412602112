.forgot-password-container {
    max-width: 600px; 
    margin: 1px auto 0; 
    background-color: #fff; /* Set a background color */
    padding: 20px; /* Add padding to the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for a card-like effect */
    border-radius: 10px; /* Add rounded corners */
    /* padding-top: 20px; */
    min-height: 400px;
    /* height: 600px; */

    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    
}



.forgot-password-container p {
    text-align: center; /* Center the text within the paragraph */
  }
  
  .forgot-password-container input {
    width: 50%; /* Make the input take the full width */
    margin-bottom: 10px; /* Add some space below the input */
  }
  
  .forgot-password-container button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .forgot-password-container button:hover {
    background-color: #0056b3;
  }





  .password-reset-container {
    max-width: 600px; 
    margin: 1px auto 0; 
    background-color: #fff; /* Set a background color */
    padding: 20px; /* Add padding to the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a shadow for a card-like effect */
    border-radius: 10px; /* Add rounded corners */
    /* padding-top: 20px; */
    min-height: 400px;
    /* height: 600px; */

    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    
}



.password-reset-container p {
    text-align: center; /* Center the text within the paragraph */
  }
  
  .password-reset-container input {
    width: 100%; /* Make the input take the full width */
    margin-bottom: 10px; /* Add some space below the input */
  }
  
  .password-reset-container button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .password-reset-container button:hover {
    background-color: #0056b3;
  }

  .user-type-table {
    width: 100%;
    margin: 10px 0;
    
  }
  
  .user-type-table label {
    display: block;
    margin-bottom: 5px;
  }