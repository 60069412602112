.about_section {
  background-color: #00204a;
  color: #ffffff;
}

.about_section .heading_container {
  padding: 30px
}

.row-aboutus {
  align-items: center;
}

.img-box{
  height: 550px;
}

.detail-box{
  padding: 70px;
}

// .about_section  .h2-aboutus-wwr{
//   display: block;
//   font-size: 1.5em;
//   margin-block-start: 0.83em;
//   margin-block-end: 0.83em;
//   margin-inline-start: 0px;
//   margin-inline-end: 0px;
//   font-weight: bold;
//   unicode-bidi: isolate;
// }