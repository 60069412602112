.archives-link {
    
  margin-top: 17px; 
}

.vendor .content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
  }
  
  .vendor .box {
    background-color: #fff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 4%), 0 0 2px rgb(0 0 0 / 6%), 0 0 1px rgb(0 0 0 / 4%);
  }
  .vendor .ima {
    width: 150px;
    height: 100px;
  }
  .vendor img {
    width: 160px;
    height: 100px;
    object-fit: cover;
  }
  .vendor h3 {
    padding: 10px;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    .vendor .content {
      grid-template-columns: repeat(3, 1fr);
    }
  }




  /* style.css */

.image-list {
  width: 80%; /* Adjust width as needed */
  margin: 0 auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #e9e9e9;
}

.main-heading {
  font-size: 28px;
  font-weight: bold;

  margin-top: 20px;
}

.description {
  font-size: 16px;
  line-height: 1.5;
  margin-left :50px;
  /* Add other styles as needed */
}
