
.press{
    margin: 10px 0;
    color: black;
    margin-bottom: 40px;

}
.press a{
    color: #fff;
 }

 .press .box{
    position: relative;
    padding: 5px;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 1px rgb(198, 197, 197);
    
 }

 .press img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
    filter: brightness(70%)
 }

 

 .press .text h1{
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
}
.press .author span{
    font-size: 12px;
    margin-right: 20px;
    font-weight: 400;
}
.press .text {
    position:relative;
    margin-top: 30%;
    bottom: 2px;
    height: 50%;
    text-decoration: none;
}




.press .box:nth-child(1) .category {
    background-color: crimson ;
}
.press .box:nth-child(2) .category {
    background-color: #0b9931 ;
}

.press .box:nth-child(4) .category {
    background-color: #c78823 ;
}

.press .container{
    display: grid;
    grid-template-columns: (4fr 4fr 4fr 4fr);
    grid-template-rows: (4fr 4fr 4fr 4fr);
    grid-gap:15px;
    
 }

.press .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
}
.press .box:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 4;
}
.press .box:nth-child(3) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 4;
}
.press .box:nth-child(4) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 4;
}
.press .box:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 7;
}
.press .box:nth-child(6) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 7;
}
.press .box:nth-child(7) {
    grid-column-start:5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 7;
}
.press .box:nth-child(8) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 7;
}

.press .box:nth-child(9) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 11;
}
.press .box:nth-child(10) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 11;
}
.press .box:nth-child(11) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 7;
    grid-row-end: 11;
}
.press .box:nth-child(12) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 11;
}

@media screen and (max-width: 768px) {
    
    .press .container {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(18,6vh);
        grid-gap:10px;
     }
    
    .press .box:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 5 ;
    }
    .press .box:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 5;
        grid-row-end: 10;
    }
    .press .box:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 10;
        grid-row-end: 15;
    }
    .press .box:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .press .box:nth-child(5) {
        display: none;
    }
    .press .box:nth-child(6) {
        display: none;
    }
    .press .box:nth-child(7) {
        display: none;
    }
    .press .box:nth-child(8) {
        display: none;
    }
    .press .box:nth-child(9) {
        display: none;
    }
    .press .box:nth-child(10) {
        display: none;
    }
    .press .box:nth-child(11) {
        display: none;
    }
    .press .box:nth-child(12) {
        display: none;
    }
    
} 
