/* insertemails.css */

/* Centering the form */
.insert-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* Styling the form */
.insert-email-container form {
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
}

/* Headings */
.insert-email-container h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Form labels */
.insert-email-container label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

/* Form inputs */
.insert-email-container input[type="email"],
.insert-email-container input[type="password"],
.insert-email-container input[type="text"],
.insert-email-container input[type="number"],
.insert-email-container select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
}

/* Submit button */
.insert-email-container .insert-button {
  background-color: #28a745;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

/* Hover effect for button */
.insert-email-container .insert-button:hover {
  background-color: #218838;
}

/* Responsive design for mobile */
@media (max-width: 600px) {
  .insert-email-container form {
    width: 90%;
  }
}
