.hero_area {
  position: relative;
  min-height: 100vh;
  align-items: center;
}

.hero_bg_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  z-index: -1;

  .bg_img_box {
    min-width: 100%;
    min-height: 100%;
  }

  img {
    min-width: 100%;
    min-height: 100%;
  }

}

.logo{
  max-width: 15%;
  padding-top: 50px
}