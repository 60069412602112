.add-company-form-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.add-company-form-container h1 {
  font-size: 1.8rem;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.add-company-form-container .form-group {
  margin-bottom: 15px;
}

.add-company-form-container .form-group label {
  display: block;
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.add-company-form-container .form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-company-form-container .form-group input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.25);
}

.add-company-form-container .form-group input[type="number"]::-webkit-inner-spin-button,
.add-company-form-container .form-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-company-form-container .register-button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.add-company-form-container .register-button:hover {
  background-color: #0056b3;
}

.add-company-form-container .register-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
