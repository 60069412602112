/* const Styles = styled.div`
 background: lavender;
 padding: 20px; */

 .health-h1 {
   border-bottom: 1px solid white;
   color: #3d3d3d;
   font-family: sans-serif;
   font-size: 20px;
   font-weight: 600;
   line-height: 24px;
   padding: 10px;
   text-align: center;
 }

 .form-style {
   background: rgb(228, 234, 235);
   border: 1px solid #c0c0c2;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: 0 auto;
   max-width: 600px;
   padding: 30px 50px;
   margin-top: 50px;
   border-radius: 4px;
 }

 .form-style input,select {
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   box-sizing: border-box;
   padding: 10px;
   width: 100%;
 }

 .employee-form-style label {
   color: #3d3d3d;
   display: block;
   font-family: sans-serif;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
   /* margin-left: 50px; */
 }

 .error {
   color: red;
   font-family: sans-serif;
   font-size: 12px;
   height: 30px;
 }

 .button-style {
   background-color: #375cd6;
   color: rgb(255, 255, 255);
   font-family: sans-serif;
   font-size: 14px;
   margin: 20px 0px;
   border: none;
}
.button-style:hover{
  background-color: #090ba0;
 
}
/* .employee-form-style{
  
} */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.login-button{
  background-color: #375cd6;
  color: white;
  
}


/* ###################################################### */

.App {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h3 {
  text-align: center;
}

.topping {
  margin-top: 0.3rem;
  vertical-align: text-bottom;
}

.result {
  margin-top: 1rem;
}

.toppings-list,
.total {
  width: 30%;
  margin: 0 auto;
}

.toppings-list {
  list-style: none;
  padding: 0;
}

.toppings-list li {
  margin-bottom: 0.5rem;
}

.toppings-list-item {
  display: flex;
  justify-content: space-between;
}

.toppings-list li:last-child {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
}

.toppings-list-item label {
  vertical-align: text-bottom;
  margin-left: 0.2rem;
}

.total {
  margin-top: 1rem;
}
.check-box-conatainer{
  display: flex;
}
.food-type{
  width: 15%;

}
.check-box-input-label-conatainer{
  width: 15%;
  height: 50px;
}

table {
  border-collapse: collapse !important;
}

table, th, td {
  border: none !important;
}

@media screen and (max-width: 768px) {
  .toppings-list,
  .total {
    width: 100%;
  }

  

 


}

.input-checkbox{
  width:20px;
  height:20px;
  margin-left:10%;
}

.input-radio-heading{
  width:20px;
  height:20px;
  margin-left:5%;

}
.food-habit-heading-div{
  margin-left: 10%;
  
}
.input-radio{
  width:5%;
  height:20px;
  margin-left:15%;
}

/* .radio-label{
  width:30px
} */



