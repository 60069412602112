.top-points-container {
    max-width: 600px;
    margin: 0 auto;
    
  }
  
  .points-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
  }
  
  .points-table th, .points-table td {
    padding: 8px;
    text-align: left;
  }
  
  .points-table th {
    background-color: #f2f2f2;
    border-bottom: 2px solid #ddd;
  }
  
  .points-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .points-table tr:hover {
    background-color: #f2f2f2;
  }

  @media screen and (max-width: 768px) {

    .top-points-container {
        max-width: 600px;
        margin: 0 auto;
        
      }

  }