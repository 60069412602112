.follower-container {
    padding: 20px;
    max-width: 1320px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
  }
  
  .follower-container h4 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #333;
  }
  
  .follower-container .loading-indicator {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .follower-container .error-alert {
    margin: 20px 0;
  }
  
  .company-info-container {
    margin-top: 20px;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .company-info-container h5 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #555;
  }
  
  .company-info-container .MuiTableContainer-root {
    margin-top: 10px;
    overflow-x: auto;
  }
  
  .competitors-container {
    margin-top: 30px;
  }
  
  .competitors-container ul {
    list-style: none;
    padding: 0;
  }
  
  .competitors-container li {
    margin-bottom: 15px;
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .competitors-container li h6 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #444;
  }
  
  .competitors-container li p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  /* Mobile-specific styles */
  @media (max-width: 600px) {
    .follower-container {
      padding: 15px;
    }
  
    .follower-container h4 {
      font-size: 1.5rem;
    }
  
    .company-info-container h5,
    .competitors-container li h6 {
      font-size: 1.2rem;
    }
  
    .competitors-container li p {
      font-size: 0.85rem;
    }
  
    .MuiTableCell-root {
      font-size: 0.8rem;
      padding: 5px;
    }
  }
  