.image-upload {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px;
}

.image-upload__form {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
}

.image-upload__label {
	font-size: 1rem;
	margin-bottom: 5px;
}

.image-upload__select,
.image-upload__file-input {
	padding: 8px;
	margin-bottom: 15px;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.image-upload__button {
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

	.image-upload__button:hover {
		background-color: #0056b3;
	}

.image-upload__response {
	margin-top: 20px;
	text-align: center;
	color: green;
	font-weight: bold;
}

/* Responsive styling */
@media (max-width: 768px) {
	.image-upload__form {
		max-width: 100%;
	}

	.image-upload__button {
		width: 100%;
	}
}
