/* Add this to your style/Questions.css file */
.main-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .added-probs-container {
    width: 45%; /* Adjust the width as needed */
  }
  

.form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    margin-top: 100px;
    width: 500px;
    height: auto;
    
  
    .drop {
      margin-bottom: 20px;
      width: 500px;
      padding: 5px

    }
  
    .textbox {
      width: 500px;
      height: 150px; /* Initial height */
      resize: vertical; /* Allow vertical resizing */
      margin-bottom: 10px;
      /* Add more styling for the textarea as needed */
    }
    h4{
        margin-top: -5px;
    }
    h5{
        margin-top: -5px;
    }
    button {
        background-color: rgb(56, 56, 211);
        margin-top: 50px;
      
        &:hover {
          background-color: rgb(38, 38, 163);
          color: #fff; /* Change text color on hover */
          /* Add any other styles for the hover effect */
        }
      
        &:active {
          background-color: rgb(161, 174, 239);
          /* Add any additional styles for the active effect */
        }
      }    
     .success-message{
        text-align: left;
        width:50px;
     }
  }
  