@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.content-textarea {
  padding: 10px;
  margin: 0 auto 0 0;
  height: 100%;
  display: block;
}

.content-container {
  margin: 0 auto;
  overflow-y: auto;
}

.approve-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


.content-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.story-image {
  max-width: 650px;
  max-height:400px;
  object-fit: cover;
  margin-left: 1px;
}

.image-preview-container {
  position: relative;
  width: 50%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

  .content-textarea {
    width: 100%;
    margin-right: 0;
    /* Reset margin for mobile view */
  }

.preview-post {
  .approve-header {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 30px;
    font-weight: 600;
    padding: 15px;
    text-align: center;
  }

  .post-content {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding: 10px 20px;
  }
}

.previous-post{
  padding: 40px;
}