/* Table styles */
.table-container {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    overflow-x: auto;
  }
  
  .table-container th {
    background-color: #f2f2f2;
    text-align: left;
    padding: 8px;
  }
  
  .table-container td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
  }
  
  .table-container tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table-container tr:hover {
    background-color: #ddd;
  }
  
  /* Responsive table for smaller screens */
  @media screen and (max-width: 600px) {
    .table-container {
      overflow-x: auto;
    }
    .table-container th, .table-container td {
      white-space: nowrap;
    }
  }
  