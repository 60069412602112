.trendsShowFollowerCount-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
    /* padding: 20px; */
  }
  
  .trendsShowFollowerCount-container h1 {
    margin-bottom: 20px;
  }
  
  .trendsShowFollowerCount-select {
    max-width: 300px; /* Set a max-width for the select box */
    width: 100%; /* Make it responsive */
    padding: 10px;
    margin-bottom: 20px; /* Space between select box and the rest of the content */
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .trendsShowFollowerCount-btn-navigate {
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .trendsShowFollowerCount-btn-navigate:hover {
    background-color: #218838;
  }
  
  .trendsShowFollowerCount-btn-navigate:focus {
    outline: none;
  }
  
  .trendsShowFollowerCount-btn-navigate:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .trendsShowFollowerCount-btn-navigate:disabled:hover {
    background-color: #ccc;
  }
  
  .trendsShowFollowerCount-btn-navigate:active {
    transform: translateY(1px);
  }
  
  .trendsShowFollowerCount-loading-text {
    width: 100px; /* Adjust width as needed */
    text-align: left; /* Align text to the left */
    display: inline-block; /* Make the container inline block */
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .trendsShowFollowerCount-loading-dots {
    display: inline-block; /* Make the dots inline */
    width: 50px; /* Adjust width as needed */
    text-align: left; /* Align dots to the right */
  }

  .trendsShowFollowerCount-container ul {
  list-style-type: none;
  padding: 0;
}

/* Style for each follower count item */
.trendsShowFollowerCount-container li {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for title */
.trendsShowFollowerCount-container p.title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

/* Style for Facebook page link */
.trendsShowFollowerCount-container p.facebook-page {
  margin-bottom: 5px;
}

/* Style for follower count */
.trendsShowFollowerCount-container p.followers-count {
  margin-bottom: 5px;
  color: #666;
}

/* Style for line break */
.trendsShowFollowerCount-container br {
  margin-top: 10px;
  border: none;
}




.trendsShowFollowerCount-container ul {
  list-style-type: none;
  padding: 0;
}

/* Style for each follower count item */
.trendsShowFollowerCount-container li {
  margin-bottom: 20px;
  padding: 20px; /* Increased padding for more spacing inside the box */
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 800px; /* Set a fixed width for the boxes */
}

/* Style for title */
.trendsShowFollowerCount-container p.title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

/* Style for Facebook page link */
.trendsShowFollowerCount-container p.facebook-page {
  margin-bottom: 5px;
}

/* Style for follower count */
.trendsShowFollowerCount-container p.followers-count {
  margin-bottom: 5px;
  color: #666;
}

/* Style for line break */
.trendsShowFollowerCount-container br {
  margin-top: 10px;
  border: none;
}