.trends-home-container {
    margin: 0;
    padding: 0;
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* background-image: url("./images/background-2.jpg"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }