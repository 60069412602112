/* styles.css */

.content-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.content-table thead th {
  font-weight: bold;
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.content-table tbody td {
  padding: 8px;
}

.content-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.checkbox-header {
  width: 50px;
  /* Adjust width as needed */
}

.checkbox-column {
  width: 50px;
  /* Adjust width as needed */
  vertical-align: middle;
  /* Align checkboxes vertically in the middle */
  padding: 8px;
  /* Add padding to align checkboxes properly */
}

.checkbox-column input[type="checkbox"] {
  margin: 0;
  /* Reset margin */
}

.button-blue {
  text-decoration: none;
  background-color: #005af0;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  outline: none;
  transition: 0.3s;
  border-radius: 5px;

  &:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
  }

  &:disabled {
    background-color: #d1d1d1;
    color: #757779;
    outline: none;
  }
}

.instagram-table {
  table {
    width: 100%;
    table-layout: fixed;
    border: 3px solid black;
    box-shadow: .5rem .5rem 0 black;
    border-radius: 20px;
  }

  th {
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    background-color: #005af0;
    font-family: "IBM Plex Sans";
  }

  td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    color: #000000;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }

  tr {
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}