.trends-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  justify-content: center; /* Centers content vertically */
}

.category-buttons {
  margin-top: 20px; /* Adjust margin as needed */
}

.centered-button {
  margin-top: 20px; /* Adjust margin as needed */
}
  
  .btn-navigate {
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-navigate:hover {
    background-color:  #218838;
  }
  
  .btn-navigate:last-child {
    margin-right: 0;
  }
  
  .btn-navigate:not(:last-child) {
    margin-right: 10px;
  }
  
  .btn-navigate:focus {
    outline: none;
  }
  
  .btn-navigate:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .btn-navigate:disabled:hover {
    background-color: #ccc;
  }
  
  .btn-navigate:active {
    transform: translateY(1px);
  }

 
  .btn-go-back {
    background-color: #dc3545; /* Red color */
  }
  
  .btn-go-back:hover {
    background-color: #c82333; /* Darker red for hover */
  }



  .loading-text {
    width: 100px; /* Adjust width as needed */
    text-align: left; /* Align text to the left */
    display: inline-block; /* Make the container inline block */
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .loading-dots {
    display: inline-block; /* Make the dots inline */
    width: 50px; /* Adjust width as needed */
    text-align: left; /* Align dots to the right */
  }
  
  /* .blink-dot {
    animation: blink 1s infinite;
    opacity: 0;
  }
  
  @keyframes blink {
    50% {
      opacity: 1;
    }
  } */