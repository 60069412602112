/* DisplayMedia.css */

/* Specific container styling for DisplayMedia */
.display-media-container .display-media-container__media {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Headings styling inside DisplayMedia container */
.display-media-container h1 {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.display-media-container h1::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #007bff;
  margin: 10px auto;
}

/* Loading text styling */
.display-media-container p {
  font-size: 18px;
  color: #666;
  text-align: center;
  margin-top: 20px;
}

/* Link styling inside media container */
.display-media-container .display-media-container__media a {
  color: #007bff; /* Blue for links */
  text-decoration: none; /* Remove underline */
  font-weight: bold;
  transition: color 0.3s, text-decoration 0.3s;
}

.display-media-container .display-media-container__media a:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: underline;
}

/* Non-clickable text styling inside media container */
.display-media-container .display-media-container__media span {
  color: #888; /* Gray for plain text */
  font-weight: bold;
}

/* Add spacing between items inside media container */
.display-media-container .display-media-container__media a,
.display-media-container .display-media-container__media span {
  display: block;
  margin-bottom: 10px;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .display-media-container .display-media-container__media {
      padding: 10px;
  }

  .display-media-container h1 {
      font-size: 24px;
  }

  .display-media-container p {
      font-size: 16px;
  }
}
