.row-aboutus {
	display: flex;
	flex-direction: row;
}

.banner {
	width: 100%;
	transition: all 0.3s;

	.image {
		margin: 45px 45px;
		animation: upDown 5s infinite;
	}

	.text {
		width: 100%;
		margin-bottom: 100px;
		font-family: monospace;

		.white {
			color: white;
			font-size: 45px;
		}
	}
}

@keyframes upDown {
	0% {
		transform: translateY(-15px);
	}

	50% {
		transform: translateY(15px);
	}

	100% {
		transform: translateY(-15px);
	}
}

.banner-image {
	width: 100%
}