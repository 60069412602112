.test-questions-container {
    display: flex;
    flex-wrap: wrap; /* Allows responsiveness */
    justify-content: space-between;
    max-width: 1200px; /* Increased width for the container */
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: 200px;
}

.test-questions-form {
    flex: 1 1 70%; /* Increased width allocation for the form */
    display: flex;
    flex-direction: column;
    margin-right: 20px; /* Space between the form and media container */
    word-wrap: break-word;
    overflow: hidden;
}

.test-questions-media {
    flex: 1 1 28%; /* Slightly reduced media width to balance layout */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f9f9f9;
}

@media screen and (max-width: 768px) {
    .test-questions-container {
        flex-direction: column;
    }

    .test-questions-form,
    .test-questions-media {
        flex: 1 1 100%; /* Full-width on smaller screens */
        margin-right: 0;
    }
}
