.info_section {
    background-color: #00204a;
    color: #ffffff;
    padding: 45px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .info_section h4 {
    font-weight: 600;
  }

  .info_section_content{
    display: flex;;
    flex-direction: row;
    padding: 0 15px;
  }
  .icon{
    height:25px;
    padding: 0 5px
  }