/* .hero_news{
    margin: 5px 0;
    color: black;

}
 .hero_news a{
    color: #fff;
 }

 .hero_news .box{
    position: relative;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 1px rgb(198, 197, 197);
    height: auto;
    
 }
 .hero_news img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
    filter: brightness(70%)
 }
 .hero_news .box:nth-child(1) img {
   height: 100%;
}
 .hero_news .container{
    display:grid;
    grid-template-columns: repeat(8fr, 1fr);
    grid-template-rows: repeat(8,6.6vh);
    grid-gap:15px;
    
 }

.hero_news .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 7;
}
.hero_news .box:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 4;
}
.hero_news .box:nth-child(3) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 4;
}
.hero_news .box:nth-child(4) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 7;
}
.hero_news .box:nth-child(5) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 7;
}
.hero_news .box:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 10;
}
.hero_news .box:nth-child(7) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 10;
}
.hero_news .box:nth-child(8) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 7;
    grid-row-end: 10;
}
.hero_news .box:nth-child(9) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 10;
}
.hero_news .box:nth-child(10) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 10;
    grid-row-end: 13;
}

.hero_news .text h1{
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
}
.hero_news .author span{
    font-size: 12px;
    margin-right: 20px;
    font-weight: 400;
}
.hero_news .text {
    position: absolute;
    bottom: 0px;
    height: 50%;
    text-decoration: none;
}
.hero_news .box:nth-child(1) .text {
    height: 30%;
    bottom: 10px !important;
}
.hero_news .box:nth-child(1) h1 {
    font-size: 28px;
    color: #fff;
}
.hero_news .box:nth-child(1) span{
    color: #fff;
}

.hero_news .box:nth-child(1) .category {
    background-color: crimson ;
}
.hero_news .box:nth-child(2) .category {
    background-color: #0b9931 ;
}

.hero_news .box:nth-child(4) .category {
    background-color: #c78823 ;
}

@media screen and (max-width: 768px) {
    
        .hero_news .container {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(18,6vh);
            grid-gap:10px;
         }
        
        .hero_news .box:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 9;
            grid-row-start: 1;
            grid-row-end: 5 ;
        }
        .hero_news .box:nth-child(2) {
            grid-column-start: 1;
            grid-column-end: 9;
            grid-row-start: 5;
            grid-row-end: 10;
        }
        .hero_news .box:nth-child(3) {
            grid-column-start: 1;
            grid-column-end: 9;
            grid-row-start: 10;
            grid-row-end: 15;
        }
        .hero_news .box:nth-child(4) {
            grid-column-start: 1;
            grid-column-end: 9;
            grid-row-start: 15;
            grid-row-end: 20;
        }
        .hero_news .box:nth-child(5) {
            display: none;
        }
        .hero_news .box:nth-child(6) {
            display: none;
        }
        .hero_news .box:nth-child(7) {
            display: none;
        }
        .hero_news .box:nth-child(8) {
            display: none;
        }
        .hero_news .box:nth-child(9) {
            display: none;
        }

    
} */

.news-container .archives-link {
    
    margin-top: 17px; 
}



.news-container .hero_news{
    margin: 10px 0;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
   /* margin-bottom: 150px; */

}
.news-container .hero_news a{
    color: black;
 }

 .news-container .hero_news .box{
    position: relative;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 1px rgb(198, 197, 197);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    
    
 }
 
 .news-container .hero_news img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    
    border-radius: 2px;
 }

 .news-container .hero_news .box:nth-child(1) img {
    height: 75%;
 }
 .news-container .hero_news .content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;

  }

  .news-container .hero_news .text h1{
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    margin-bottom: 5px;
}
.news-container .hero_news .author span{
    font-size: 12px;
    margin-right: 20px;
    font-weight: 400;
   
}
.news-container .hero_news .text {
    position: relative;
    padding: 10px;
    text-align: center;
    color: black;
    background: none;
    opacity: 0.9;
    z-index: 1;
    margin-top: 140px; /* Reset margin-top */
    margin-bottom: -20px; /* Add margin to create space between text and image */
}
.news-container .hero_news .text h1,
.news-container .hero_news .text span{
    width: 100%;
    display: block;
    margin: 0; /* Remove default margins */
 
}

  
.news-container .hero_news .box:nth-child(1) .text {
    
    height: 20%;
    bottom: 10px;
    margin-top: 40%; 
}
.news-container .hero_news .box:nth-child(1) h1 {
    font-size: 28px;
    color: black;
    margin-top: -25px;
    
}
.news-container .hero_news .box:nth-child(1) span{
    color:black;
 
  
   
}

.news-container .hero_news .box:nth-child(1) .category {
    background-color: crimson ;
}
.news-container .hero_news .box:nth-child(2) .category {
    background-color: #0b9931 ;
}

.news-container .hero_news  .box:nth-child(4) .category {
    background-color: #c78823 ;
}

.news-container .hero_news  .container{
    display: grid;
    grid-template-columns: repeat(8fr, 1fr);
    grid-template-rows: repeat(8,6.6vh);
    grid-gap:15px;
    min-height: 400px;
    white-space: nowrap;
    
 }

 .news-container .hero_news  .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 7;
   
}
.news-container .hero_news  .box:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 4;
}
.news-container .news-hero  .box:nth-child(3) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 4;
}
.news-container .hero_news .box:nth-child(4) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 7;
}
.news-container .hero_news .box:nth-child(5) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 7;
}
.news-container .hero_news .box:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 10;
}
.news-container .hero_news .box:nth-child(7) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 10;
}
.news-container .hero_news .box:nth-child(8) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 7;
    grid-row-end: 10;
}
.news-container .hero_news .box:nth-child(9) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 10;
}
.news-container .hero_news .box:nth-child(10) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 10;
    grid-row-end: 13;
}
.news-container .hero_news .box:nth-child(11) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 10;
    grid-row-end: 13;
}
.news-container .hero_news .box:nth-child(12) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 10;
    grid-row-end: 13;
}
.news-container .hero_news .box:nth-child(13) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 10;
    grid-row-end: 13;
}
.news-container .hero_news .box:nth-child(14) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 13;
    grid-row-end: 16;
}
.news-container .hero_news .box:nth-child(15) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 13;
    grid-row-end: 16;
}
.news-container .hero_news .box:nth-child(16) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 13;
    grid-row-end: 16;
}
.news-container .hero_news .box:nth-child(17) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 13;
    grid-row-end: 16;
}
.news-container .hero_news .box:nth-child(18) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 16;
    grid-row-end: 19;
  }
  
  .news-container .hero_news .box:nth-child(19) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 16;
    grid-row-end: 19;
  }
  
  .news-container .hero_news .box:nth-child(20) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 16;
    grid-row-end: 19;
  }
  
  .news-container .hero_news .box:nth-child(21) {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 16;
    grid-row-end: 19;
  }
  .hero_news .title h1 {
    margin-top:0px;
  }
  @media screen and (max-width: 768px) {
    .news-container .hero_news .box .text h1 {
        font-size: 16px; /* Reduce font size for smaller screens */
      }
    
      .news-container .hero_news .box .text .date-wrapper {
        height: 15px; /* Reduce height of date span for smaller screens */
      }
    

    .news-container  .hero_news .container {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-auto-rows: repeat(18, 6vh);
        grid-gap: 10px;
        white-space: nowrap;
    }
    
    .news-container .hero_news .box:nth-child(1) .text {
        
        margin: 140px 0px -30px;
        padding: 85px;
    }
    
    .news-container  .hero_news .box:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 5 ;
    }
    .news-container .hero_news .box:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 5;
        grid-row-end: 10;
    }
    .news-container .hero_news .box:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 10;
        grid-row-end: 15;
    }
    .news-container .hero_news .box:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 15;
        grid-row-end: 20;
    }
    .news-container .hero_news .box:nth-child(5) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(6) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(7) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(8) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(9) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(10) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(11) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(12) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(13) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(14) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(15) {
        display: none;
    }
    .news-container .hero_news .box:nth-child(16) {
        display: none;
    }
    .news-container  .hero_news .box:nth-child(17) {
        display: none;
    }
    
}
