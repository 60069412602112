/* HbReleases.css */
.hb-releases-container {
    padding: 20px;
  }
  
  .hb-columns-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align columns */
  }
  
  .hb-column-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin: 10px;
    width: 300px; /* Adjust width as needed */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add box shadow for depth */
    background-color: #fff; /* White background */
    transition: transform 0.3s ease; /* Add smooth hover effect */
  }
  
  .hb-column-box:hover {
    transform: translateY(-5px); /* Move box up on hover */
  }
  
  .hb-column {
    margin-bottom: 10px;
  }
  
  .hb-column a {
    color: #007bff; /* Blue color for PDF links */
    text-decoration: none;
    transition: color 0.3s ease; /* Add smooth color transition */
  }
  
  .hb-column a:hover {
    color: #0056b3; /* Darker blue on hover */
  }
  