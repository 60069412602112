.dochead{
    margin-left: 20px;
    padding-top: 50px;
    padding-bottom: 30px;
}
.docimg{
    height: 200px;
    width: 200px;
}
.total{
    padding-left: 60px;
    padding-right: 60px;
}

.total h6{
    padding-top: 30px;
}
.total h2{
    color: red;
}
.imgs{
    height: 500px;
    width: 500px;
}
.heroimg{
    height:250px;
    width: 400px;
}
.herocontain{
    padding-left: 20px;
    padding-right: 20px;
}
