

.flash{
    margin:  0;
    color: black;
}

.flash a {
    color: black;
}
.flash  .box{
     position:relative;
     padding: 20px;
     box-shadow: 2px 2px 2px 2px rgb(198, 198, 198);
     border-radius: 5px;
 }
 .flash a{
    text-decoration: none;
 }

 .flash img{
    width: 100%;
    height: 60%;
    object-fit: cover;
    position: absolute;
    border-radius: 5px;
    top: 0;
    left: 0;
    z-index: -1;
}
.flash .container{
    display:grid;
    grid-template-columns: repeat(8,1fr);
    grid-template-rows: repeat(8,6.6vh) ;
    grid-gap :10px;
}

.flash .box:nth-child(1){
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 9;
}


.flash .text h1 {
font-weight: 500;
margin: 10px 0;
font-size: 20px;


}
.flash .text h2 {
    font-weight: 300; 
    font-size: 15px;
    
    }

.flash .author span{
font-size: 14px;
margin-right: 20px;
}

.flash .text{
    position: absolute;
    bottom: 40px;
}

.flash .box:nth-child(1){
    font-size: 28px;

}
.flash .box:nth-child(1) .category{
    background-color: crimson;
}
.hero .box:nth-child(2) .category{
    background-color: #0b9931;
}
.flash .box:nth-child(4) .category{
    background-color: #c78823;
}
@media screen and (max-width:768px) {
    .flash .container{
        display: grid;
        grid-template-columns: repeat(8,1fr);
        grid-template-rows: repeat(8,6vh) ;
        grid-gap :10px;
    }
    
    .flash  .box:nth-child(1){
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 9;
    }
    
}
@media screen and (max-width: 1200px) {
    .vendor .content {
        grid-template-columns: repeat(4, 1fr); /* 4 columns for medium screens */
    }
}

/* Adjust for tablets and smaller screens */
@media screen and (max-width: 768px) {
    .vendor .content {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
}

/* Adjust for mobile screens */
@media screen and (max-width: 480px) {
    .vendor .content {
        grid-template-columns: 1fr; /* 1 column for mobile screens */
    }
}
