.v-form h2{
  color: #212A3E;
}

.v-body {
  background-color: #cfe1e6;
}

.v-form {
  width: 580px;
  box-sizing: border-box;
  height: auto;
  margin: 50px auto;
  background-color: #c0e5f3;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 20px 20px 20px rgba(0.6, 0.1, 0, 0.2);

}

label {
  font-weight: bold;
}


.vn-input,
.vn-select {
  display: grid;
  height: 15px;
  width: 500px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  font-family: "Comic Sans MS";
}


.vn-select {
  height: 40px;
}

.v-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 3px;
}

.v-button:hover {
  background-color: #0056b3;
}

.v-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}



textarea {
    height: 120px;
    width: 470px; /* Occupies full width of its container */
    margin-bottom: 20px;
  }
.v-input{
  
  display: flex;

}

 .actions {
  display: flex;
}

.actions button {
  margin-right: 5px;
} 

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th,
table td {
  padding: 8px;
  border: 1px solid #ddd;
}

table th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #f5f5f5;
}

.row-container {
  display: flex;
  flex-direction: row;
}

.input-container {
  margin-right: 20px; /* Adjust the margin as needed to create space between the fields */
}
.phone{
 padding:6px;
 border: 1px solid #ccc;
 border-radius: 4px;
 margin-bottom: 5px;
 margin-left: 20px;
}

/* POpup.css */

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: 100%;
  height: 100%;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Additional styles for success message */
/* .success-message {
  background-color: #4caf50;
  color: white;
  padding: 15px;
  text-align: center;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 5px;
} */



.success-message {
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  padding: 15px;
  text-align: center;
  position: fixed;
  top: 20px; /* Adjust this value to position the message further down */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin-top: 50px; /* Adds space above the message */
  line-height: .1;
}

.success-message.show {
  opacity: 1;
}

.success-message p {
  color: white;
}
