.email-set-default-container {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
  }
  
  .email-set-default-container .email-set-default-header {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .email-set-default-container .email-set-default-list {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 600px;
  }
  
  .email-set-default-container .email-set-default-list-item {
    background-color: #fff;
    padding: 16px;
    margin-bottom: 25px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .email-set-default-container .email-set-default-list-item-paragraph {
    margin: 0;
    font-size: 20px;
    color: #555;
  }
  
  .email-set-default-container .email-set-default-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .email-set-default-container .email-set-default-button:hover {
    background-color: #0056b3;
  }
  