.space {
  margin-right: 15px; /* Adjust the value as needed */
  
}

.ahref {
  font-weight: bold;
}

.tab-container{
  margin-left: 50px;
  display:flex;
}

.tab{
  display: flex;
  position: relative;
  color:red;
  background-color: #ccc8c8;
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  padding: 0.75rem;
  border-radius: 99px;
  font-weight: bold;
  text-decoration: none;
}