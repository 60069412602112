/* .testqm-form-container {
    max-width: 600px; 
    margin: 1px auto 0; 
    background-color: #fff; 
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    border-radius: 10px; 
   
    min-height: 200px;
   
    display: flex; 
    justify-content: center; 
    align-items: center;

    
} */


.testqm-form-container {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}


/* .testqm-form-outer-container {
    
    white-space: nowrap;
} */

.testqm-form-container h2 {
    margin: 0;
}

.testqm-form-container p {
    margin: 20px 0;
    font-size: 30px;
}

.textarea-summary {
    width: 100%;
    /* Take up the full width of the container */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    /* Allow vertical resizing of the textarea */
    height: 200px;
}

.textarea-detail {
    width: 100%;
    /* Take up the full width of the container */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    /* Allow vertical resizing of the textarea */
    height: 500px;
}



.testqm-button {
    padding: 10px 20px;
    /* background-color: #007bff; */
    background-color: rgb(16, 58, 131);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 365px;
    margin-left: 365px;
}

.testqm-button-right-left {
    padding: 10px 20px;
    /* background-color: #007bff; */
    background-color: rgb(16, 58, 131);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
   
}




.left-buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* Add this line */
}

.right-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* Add this line */
}




.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}



@media screen and (max-width: 768px) {

    .testqm-button {
        padding: 10px 20px;
        /* background-color: #007bff; */
        background-color: rgb(16, 58, 131);
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;
        margin-right: 50px;
        margin-left: 50px;

    }

}