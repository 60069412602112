/* LabTestScreen.css */
.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .buttonContainer {
    display: flex; /* Change to display flex */
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .buttonWrapper {
    margin-bottom: 5px;
    width: 100%; /* Set width to 100% */
  }
  
  .testButton {
    padding: 10px 20px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    color: #333;
    width: 100%; /* Set width to 100% */
    text-align: center;
  }
  