@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
 
 
} 
body {
  font-family: "Poppins", sans-serif;
}
 p,h1,h2,h3,h4,h5,h6,span,label{
  cursor: pointer;
 }
 a{
  text-decoration: none;
 }
  .container{
    max-width: 90%;
    margin: auto;
  }
  .flexSB{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .flex{
    display: flex;
  }

  .paddingTB{
    padding: 30px 0;
  }
  .category{
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    padding: 5px 10px;
    letter-spacing: 2px;
    background-color: purple;
  }

  .header{
    background-color: rgb(16, 58, 131);
  }

  .header-techie{
    background-color: #c60021;
  }



 