.image-gallery {
    display: flex;
    flex-direction: column; /* Stack images vertically */
    align-items: center; /* Center-align items horizontally */
    gap: 16px; /* Space between images */
    padding: 20px; /* Add padding around the gallery */
  }
  
  .image-container {
    width: 80%; /* Adjust width as needed */
    max-width: 600px; /* Optional: set a maximum width */
    text-align: center; /* Center-align text */
  }
  
  .image {
    width: 100%; /* Make image fill container width */
    height: auto; /* Maintain aspect ratio */
  }
  
  .description {
    margin-top: 10px;
    font-size: 25px; /* Adjust font size */
    font-family: 'Arial', sans-serif; /* Change font family */
    font-weight: normal; /* Default weight; adjust as needed */
    color: red; /* Change text color */
  }