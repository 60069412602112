.email-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .email-item {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .bulk-email-home  {
    margin-left: 500px;
    /* align-items: center; */
    padding: 20px;
  }

  .bulk-email-home .w1-table  {
    border: 1px solid #ddd  !important;
    border-collapse: collapse;
    width: 5px;
  }
  
  .bulk-email-home .w1-table th, .bulk-email-home .w1-table td {
    border: 1px solid #ddd  !important;
    padding: 8px;
    text-align: center;
    width: 5px;
  }
  
  .bulk-div
  {
      margin-left: 100px;
  }
  .bulk-div-button
  {
      margin-left: 108px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

