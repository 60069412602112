/* Heading Styles */
.competitor-analysis-heading {
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Overall Container */
.competitor-analysis-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Navigation Styles */
.competitor-analysis-navigation {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

/* Navigation Links (Button Styling) */
.competitor-analysis-navigation-link {
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff; /* Button background color */
  padding: 12px 20px; /* Padding for button feel */
  border: none; /* Remove border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for button effect */
  transition: all 0.3s ease; /* Smooth transitions */
  text-align: center;
  width: 100%; /* Full-width buttons */
  max-width: 300px; /* Limit button width */
}

/* Hover Effect */
.competitor-analysis-navigation-link:hover {
  background-color: #0056b3; /* Darker shade on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on hover */
  transform: translateY(-2px); /* Lift effect */
}

/* Active Effect */
.competitor-analysis-navigation-link:active {
  transform: translateY(1px); /* Pressed button effect */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow change */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .competitor-analysis-navigation {
    gap: 10px; /* Reduced gap for smaller screens */
  }

  .competitor-analysis-navigation-link {
    font-size: 1.1rem; /* Slightly larger text for better readability */
    padding: 14px 20px; /* Adjust padding for touch-friendly buttons */
  }
}
