/* Container Styles */
.smp-schedule-history-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
}

/* Header Styles */
.smp-schedule-history-container .header {
    font-size: 1.8rem;
    color: #007bff;
    margin-bottom: 20px;
    text-align: center;
}

/* Table Styles */
.smp-schedule-history-container .table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.smp-schedule-history-container .table thead {
    background-color: #007bff;
    color: #fff;
}

.smp-schedule-history-container .table th,
.smp-schedule-history-container .table td {
    text-align: center;
    padding: 12px;
    border: 1px solid #ddd;
}

.smp-schedule-history-container .table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.smp-schedule-history-container .table tbody tr:hover {
    background-color: #f1f1f1;
}

/* Button Styles */
.smp-schedule-history-container .button {
    cursor: pointer;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
}

.smp-schedule-history-container .button-primary {
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s;
}

.smp-schedule-history-container .button-primary:hover {
    background-color: #0056b3;
}

.smp-schedule-history-container .button-secondary {
    background-color: #6c757d;
    color: #fff;
    transition: background-color 0.3s;
}

.smp-schedule-history-container .button-secondary:hover {
    background-color: #5a6268;
}

/* Modal Styles */
.smp-schedule-history-container .modal-header {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
}

.smp-schedule-history-container .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.smp-schedule-history-container .modal-body {
    padding: 20px;
}

.smp-schedule-history-container .modal-footer {
    padding: 10px 15px;
    text-align: right;
    border-top: 1px solid #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
    .smp-schedule-history-container {
        padding: 10px;
    }

    .smp-schedule-history-container .header {
        font-size: 1.5rem;
    }

    .smp-schedule-history-container .table th,
    .smp-schedule-history-container .table td {
        font-size: 0.8rem;
        padding: 8px;
    }

    .smp-schedule-history-container .button {
        font-size: 0.8rem;
        padding: 6px 10px;
    }
}
