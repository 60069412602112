.watermark-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px;
}

.watermark-form__header {
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.watermark-form__radio-group {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}

	.watermark-form__radio-group label {
		margin-right: 15px;
		font-size: 1rem;
	}

.watermark-form__input-container {
	margin-bottom: 15px;
	width: 100%;
	max-width: 400px;
	text-align: center;
}

	.watermark-form__input-container label {
		display: block;
		font-size: 1rem;
		margin-bottom: 5px;
	}

	.watermark-form__input-container input[type="file"],
	.watermark-form__input-container input[type="text"] {
		padding: 8px;
		width: 100%;
		border-radius: 4px;
		border: 1px solid #ccc;
	}

.watermark-form__button {
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

	.watermark-form__button:hover {
		background-color: #0056b3;
	}

/* Responsive styling */
@media (max-width: 768px) {
	.watermark-form__radio-group {
		flex-direction: column;
		align-items: flex-start;
	}

	.watermark-form__button {
		width: 100%;
	}
}
