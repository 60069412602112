/* EditProfile.css */

/* EditProfile.css */

/* Center the container horizontally and vertically */
.edit-profile-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    /* height: 100vh; */
    width: 1000px;
    min-height: 700px;
}

/* Rest of your styles remain the same */


.edit-profile-container h2 {
    font-size: 24px;
    margin-bottom: 20px;

}

.edit-profile-form .form-group {
    margin-bottom: 20px;
}

.edit-profile-form label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.edit-profile-form .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
}

.edit-profile-form .ebtn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
}

/* Add more styles as needed */
